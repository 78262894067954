import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { EN } from '@constants';

import en from './en.json';
import it from './it.json';
import no from './no';
import sk from './sk';
import nl from './nl.json';
import cs from './cz.json';
import fr from './fr.json';
import pl from './pl.json';
import es from './es.json';
import sv from './sv.json';

const resources = {
  en,
  it,
  no,
  sk,
  nl,
  cs,
  fr,
  pl,
  es,
  sv,
};

const supportedLanguages = ['en', 'it', 'no', 'sk', 'nl', 'cs', 'pl', 'fr', 'es', 'sv'];
const defaultLanguage = EN;

let languageCode = window?.location?.pathname.split('/')[2]?.toLowerCase();
if (languageCode === 'cz') {
  languageCode = 'cs';
} else if (languageCode === 'se') {
  languageCode = 'sv';
}

if (!supportedLanguages.includes(languageCode)) {
  languageCode = window?.location?.pathname.split('/')[1]?.toLowerCase();
  if (languageCode === 'cz') {
    languageCode = 'cs';
  } else if (languageCode === 'se') {
    languageCode = 'sv';
  }
}

i18n.use(initReactI18next).init({
  resources,
  fallbackLng: supportedLanguages.includes(languageCode) ? languageCode.toUpperCase() : defaultLanguage.toUpperCase(),
  compatibilityJSON: 'v3',
  lng: supportedLanguages.includes(languageCode) ? languageCode : defaultLanguage,
  debug: true,
});

export default i18n;

// import i18n from 'i18next';
// import { initReactI18next } from 'react-i18next';
// import en from './en.json';
// import it from './it.json';
// import no from './no';
// import sk from './sk';
// import nl from './nl.json';
// import cs from './cz.json';

// import { EN } from '@constants';

// const resources = {
//   en,
//   it,
//   no,
//   sk,
//   nl,
//   cs,
// };

// const supportedLanguages = ['en', 'it', 'no', 'sk', 'nl', 'cs'];
// const defaultLanguage = 'en';

// let languageCode = window?.location?.pathname.split('/')[2]?.toLowerCase();
// if (languageCode && supportedLanguages.includes(languageCode)) {
//   if (languageCode === 'cz') {
//     languageCode = 'cs';
//   }
//   i18n.use(initReactI18next).init({
//     resources,
//     fallbackLng: languageCode.toUpperCase(),
//     compatibilityJSON: 'v3',
//     lng: languageCode,
//     debug: true,
//   });
// } else {
//   languageCode = window?.location?.pathname.split('/')[1]?.toLowerCase();

//   if (languageCode === 'cz') {
//     languageCode = 'cs';
//   }

//   if (languageCode && supportedLanguages.includes(languageCode)) {
//     i18n.use(initReactI18next).init({
//       resources,
//       fallbackLng: languageCode.toUpperCase(),
//       compatibilityJSON: 'v3',
//       lng: languageCode,
//       debug: true,
//     });
//   } else {
//     i18n.use(initReactI18next).init({
//       resources,
//       fallbackLng: defaultLanguage.toUpperCase(),
//       compatibilityJSON: 'v3',
//       lng: defaultLanguage,
//       debug: true,
//     });
//   }
// }

// export default i18n;
