import { Button } from '@mui/material';
import { styles } from './styles';

const DialogButton = (props) => {
  const { size, variant, color, onClick, children, disabled } = props;
  return (
    <Button disableRipple sx={styles.button} size={size} variant={variant} color={color} onClick={onClick} disabled={disabled}>
      {children}
    </Button>
  );
};

export default DialogButton;
