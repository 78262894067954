import { useCallback, useEffect, useState } from 'react';
import { styles } from './styles';
import icon_mini_chat_close from '@images/icon_mini_chat_close.png';
import moment from 'moment';
import {
  GUEST,
  WAITING,
  REQUESTED,
  ASSIGNED,
  BLOCKED,
  POINT,
  BLOCK_MESSAGE,
  DEALER_TRANSFER,
  SERVICE_COMPLETED,
  WELCOME_H_LIVE_CHAT,
  FIREBASE_API_KEY,
  FIREBASE_AUTH_DOMAIN,
  FIREBASE_DATABASE_URL,
  FIREBASE_PROJECT_ID,
  FIREBASE_STORAGE_BUCKET,
  FIREBASE_MESSAGING_SENDER_ID,
  FIREBASE_APP_ID,
  FIREBASE_MEASUREMENT_ID,
  SOCKET_URI,
  SOCKET_NAMESPACE_NOTIFY,
  CUSTOMER,
  HOLIDAY,
  HYUNDAI_ASSISTANT,
  H_LIVE_CHAT,
  PRIVACY_POLICY,
  H_LIVE,
  TOKEN_STORAGE,
} from '@constants';
import { consoleLogDev, getInternalIpAddress, hLiveDateTimeFormat, replaceUrlLink, turnOnAlarm } from '@utils';
import { Badge, Box, Button, Typography } from '@mui/material';
import MiniChatRoom from '@components/MiniChatRoom';
import bookingStore from '@store/booking.store';
import { useFireStore } from '@store/firebase.store';
import { initializeApp } from 'firebase/app';
import { useTranslation } from 'react-i18next';
import LoadingSpinner from '@components/LottieSpinner';
import { ThemeProvider } from '@mui/system';
import { theme } from '@theme';
import DialogButton from '@components/DialogButton';
import { getAuth, signInAnonymously, updateProfile } from 'firebase/auth';

import {
  createHLiveChatRequest,
  deleteCallCenterHistory,
  getBusinessHourByCountry,
  getCallCenterCustomerHistory,
  getMiniChatToken,
  getPrivacyPolicyByCountryCode,
} from '@apis';
import { Manager } from 'socket.io-client';
import { gtmCreateMiniChat, gtmOpenChatInHistory, gtmCloseAssistant } from '@gtm';
import { io } from 'socket.io-client';

const MiniChat = ({ setMiniChatOpened, channelType, channelLink }) => {
  const {
    selectedCountry,
    selectedLanguage,
    requestId,
    setRequestId,
    externalChannelType,
    setExternalChannelType,
    externalChannelLink,
    setExternalChannelLink,
  } = bookingStore();

  const [isChatStarted, setIsChatStarted] = useState(false);
  const [chatHistoryList, setChatHistoryList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isDialogOpened, setIsDialogOpened] = useState(false);
  const [isDisclaimerOpened, setIsDisclaimerOpened] = useState(false);

  const [dealerInfo, setDealerInfo] = useState({});
  const [requestStatus, setRequestStatus] = useState('');
  const { t, i18n } = useTranslation();
  const [isOffline, setIsOffline] = useState(false);
  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');
  //
  const [includeBreakStartTime, setIncludeBreakStartTime] = useState('');
  const [includeBreakEndTime, setIncludeBreakEndTime] = useState('');
  const [isIncludeBreak, setIsIncludeBreak] = useState('');
  //
  const [messages, setMessages] = useState([]);
  //
  const [minichatUrl, setMinichatUrl] = useState();
  //
  const [isLoadingHistory, setIsLoadingHistory] = useState(true);
  //
  const [isMessageListLoading, setIsMessageListLoading] = useState(false);

  const firebaseConfig = {
    apiKey: FIREBASE_API_KEY,
    authDomain: FIREBASE_AUTH_DOMAIN,
    databaseURL: FIREBASE_DATABASE_URL,
    projectId: FIREBASE_PROJECT_ID,
    storageBucket: FIREBASE_STORAGE_BUCKET,
    messagingSenderId: FIREBASE_MESSAGING_SENDER_ID,
    appId: FIREBASE_APP_ID,
    measurementId: FIREBASE_MEASUREMENT_ID,
  };
  const app = initializeApp(firebaseConfig);

  const [countListenerByCountryCode, countListenerByRequestId] = useFireStore();

  const getTokenFromStorage = () => {
    const tokenStorage = JSON.parse(sessionStorage.getItem(TOKEN_STORAGE));
    return tokenStorage?.token;
  };

  const socketInitializer = async (requestId) => {
    const accessToken = getTokenFromStorage();

    const socket_notify = io(SOCKET_URI + SOCKET_NAMESPACE_NOTIFY, {
      transports: ['websocket'],
      auth: {
        token: `Bearer ${accessToken}`,
        type: 'MINI_CHAT',
      },
    });

    socket_notify.on('connect', () => {
      consoleLogDev(`join, { roomId: ${requestId}' }`);
      socket_notify.emit('join', { roomId: requestId, sender: CUSTOMER });
    });

    socket_notify.on('notify', () => {
      turnOnAlarm();
    });

    socket_notify.on('block', () => {
      consoleLogDev('🚀 ~ file: index.js:115 ~ socket_notify.on ~ block');
      setRequestStatus(BLOCKED);
      turnOnAlarm();
    });
  };

  //country
  useEffect(() => {
    const initializeData = async () => {
      try {
        const tokenStorage = JSON.parse(sessionStorage.getItem(TOKEN_STORAGE));

        if (tokenStorage?.token) {
          await requestCallCenterHistory();
        } else {
          consoleLogDev('empty token in storage');
          await requestToken();
          await requestCallCenterHistory();
        }
      } catch (error) {
        console.error(error);
      }
    };

    initializeData();
  }, [countListenerByRequestId, countListenerByCountryCode]);

  useEffect(() => {
    const countryCode = selectedCountry?.countryCode;
    requestPrivacyPolicyUrlMinichat({ countryCode, setMinichatUrl });
  }, []);

  useEffect(() => {
    let socket;
    (async () => {
      consoleLogDev('socket');
      socket = await socketInitializer(requestId);
    })();
    return () => {
      consoleLogDev('off');
      if (socket) socket.disconnect();
    };
  }, [requestId]);

  const getGenerationRandomString = () => {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < 21) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));

      counter += 1;
    }
    return result;
  };

  const requestToken = async () => {
    // const ipAddress = await getInternalIpAddress();
    const data = {
      ipAddress: getGenerationRandomString() + moment().format('YYYY-MM-DDTHH:mm:ssZ'),
      userType: GUEST,
      countryCode: selectedCountry?.countryCode,
      languageCode: selectedLanguage?.languageCode?.toLocaleUpperCase(),
    };

    try {
      const response = await getMiniChatToken(data);
      if (response) {
        const tokenStorage = {
          token: response?.data?.token,
          refreshToken: response?.data?.refreshToken,
        };
        sessionStorage.setItem(TOKEN_STORAGE, JSON.stringify(tokenStorage));
        return tokenStorage.token;
      }
    } catch (error) {
      console.error(error);
    }
  };

  const requestCallCenterHistory = async () => {
    try {
      const response = await getCallCenterCustomerHistory();
      // console.log('🚀 ~ requestCallCenterHistory ~ response:', response);
      if (response) {
        setChatHistoryList(response?.data);
        setIsLoadingHistory(false);
      }
      // }
    } catch (error) {
      console.error(error);
    }
  };

  const requestBusinessHourByCountry = async () => {
    try {
      const response = await getBusinessHourByCountry();
      if (response) {
        return response;
      }
    } catch (error) {
      console.error(error);
    }
  };

  const clearHistory = async () => {
    const chatIdArray = chatHistoryList?.map((obj) => ({ _id: obj._id, requestStatus: obj.requestStatus }));
    setIsLoading(true);
    try {
      const response = await deleteCallCenterHistory(chatIdArray);
      if (response === '') {
        setChatHistoryList([]);
      } else if (response !== '') {
        setChatHistoryList(response?.data);
      }
      setIsLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  const createRequest = async () => {
    const createRequestData = {
      location: {
        type: POINT,
        coordinates: [0, 0],
      },
      country: selectedCountry?.countryCode.toUpperCase(),
      bookingDate: '',
      bookingTime: '',
      requestType: H_LIVE_CHAT,
      requestStatus: REQUESTED,
      lastModifier: GUEST,
      liveConsult: true,
      externalChannelType: channelType ? channelType : 'Hyundai Assistant',
      externalChannelLink: channelLink ? channelLink : '',
      callCondition: WAITING,
    };
    // setIsLoading(true);
    try {
      const response = await createHLiveChatRequest(createRequestData);

      if (response?.success === true) {
        setRequestId(response?.data?.systemMessages[0]?.requestId);
        setRequestStatus(response?.data?.serviceRequest?.requestStatus);
        setExternalChannelType(createRequestData.externalChannelType);
        setExternalChannelLink(createRequestData.externalChannelLink);
        gtmCreateMiniChat();
        setIsChatStarted(true);
        setFirebaseAuth();
      } else if (response?.success === false) {
        setIsChatStarted(false);
        setIsDialogOpened(true);
      }
      // setIsLoading(false);
    } catch (error) {
      console.error(error);
    }
  };
  const setFirebaseAuth = async () => {
    const firebaseConfig = {
      apiKey: FIREBASE_API_KEY,
      authDomain: FIREBASE_AUTH_DOMAIN,
      databaseURL: FIREBASE_DATABASE_URL,
      projectId: FIREBASE_PROJECT_ID,
      storageBucket: FIREBASE_STORAGE_BUCKET,
      messagingSenderId: FIREBASE_MESSAGING_SENDER_ID,
      appId: FIREBASE_APP_ID,
      measurementId: FIREBASE_MEASUREMENT_ID,
    };
    const app = initializeApp(firebaseConfig);
    const auth = getAuth(app);

    // 익명 로그인
    const { user } = await signInAnonymously(auth);
    const tokenStorage = JSON.parse(sessionStorage.getItem(TOKEN_STORAGE));
    const token = tokenStorage.token;
    await updateProfile(user, {
      // @ts-ignore - Firebase types don't include customClaims
      customClaims: {
        token,
      },
    });
  };
  // externalChannelType: externalChannelType === H_LIVE ? HYUNDAI_ASSISTANT : externalChannelType,
  // setExternalChannelType('Test-Hlive');
  const onStartChat = async () => {
    try {
      const businessHourData = await requestBusinessHourByCountry();
      const scheduleData = businessHourData?.data;
      // const isOnline = checkIsOnline(scheduleData, setStartTime, setEndTime);
      const isOnline = checkIsOnline(scheduleData, setStartTime, setEndTime, setIncludeBreakStartTime, setIncludeBreakEndTime, setIsIncludeBreak);
      // console.log('🚀 ~ onStartChat ~ isOnline:', isOnline);

      if (businessHourData?.success && isOnline) {
        await createRequest();
      } else if (scheduleData === HOLIDAY) {
        setRequestStatus(HOLIDAY);
        setIsOffline(true);
        setIsChatStarted(true);
      } else {
        setRequestStatus('');
        setIsOffline(true);
        setIsChatStarted(true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  //await requestPrivacyPolicyUrlMinichat({ countryCode, setMinichatUrl });
  const requestPrivacyPolicyUrlMinichat = async (props) => {
    const { countryCode, setMinichatUrl } = props;
    try {
      const response = await getPrivacyPolicyByCountryCode(countryCode);
      setMinichatUrl(response[0]?.privacyPolicy[1].url);
    } catch (e) {
      console.error(e);
    }
  };

  const onHistoryChat = async () => {
    try {
      setIsOffline(false);
      gtmOpenChatInHistory();
      setIsChatStarted(true);
    } catch (error) {
      console.error(error);
    }
  };

  const handleChatClick = ({ requestId, dealerInfo, requestStatus, isChatBlocked }) => {
    setIsMessageListLoading(true);
    setRequestId(requestId);
    setDealerInfo(dealerInfo);
    if (isChatBlocked) {
      setRequestStatus(BLOCKED);
    } else {
      setRequestStatus(requestStatus);
    }
    onHistoryChat();
  };

  const handleCloseClick = async () => {
    gtmCloseAssistant();
    setMiniChatOpened(false);
  };

  const handleBackClick = async () => {
    await requestCallCenterHistory();
    setMessages([]);
    setIsChatStarted(false);
  };

  const MessageComponent = ({ messageData, dealer }) => {
    // console.log('🚀 ~ file: index.js:278 ~ MessageComponent ~ dealer:', dealer);
    const dealerName = dealer?.dealerFirstName || dealer?.dealerName;
    // console.log('🚀 ~ file: index.js:279 ~ MessageComponent ~ dealer?.dealerName:', dealer?.dealerName);
    // console.log('🚀 ~ file: index.js:279 ~ MessageComponent ~ dealer?.dealerFirstName :', dealer?.dealerFirstName);
    const messageContent =
      messageData?.template === WELCOME_H_LIVE_CHAT
        ? t('4530_chat_common.new_chat_started')
        : messageData?.template === ASSIGNED
        ? `${dealer?.dealerFirstName}` + t('0500_live_consult.dealer_has_joined')
        : messageData?.template === DEALER_TRANSFER
        ? t('4530_chat_common.consultant_has_changed')
        : messageData?.template === SERVICE_COMPLETED
        ? t('4530_chat_common.chat_ended')
        : messageData?.template === BLOCK_MESSAGE
        ? t('4530_chat_common.the_chat_has_been_ended_by_the_administrator')
        : messageData?.messageType === 'H_LIVE_CHAT_END'
        ? t('4530_chat_common.the_chat_has_been_ended_by_the_administrator')
        : messageData?.messageType === 'IMAGE'
        ? '[image]'
        : messageData?.messageType === 'FILE' || messageData?.messageType === 'PDF'
        ? '[file]'
        : messageData?.messageContents;
    return <Typography sx={styles.chatContent}>{messageContent}</Typography>;
  };

  const handleDisclaimer = () => {
    setIsDisclaimerOpened(!isDisclaimerOpened);
  };

  return (
    <ThemeProvider theme={theme}>
      <Box sx={styles.container}>
        {isLoading && (
          <Box sx={styles.spinnerBox}>
            <LoadingSpinner />
          </Box>
        )}

        {isDialogOpened && <Box sx={styles.overlay} />}
        {isDialogOpened && (
          <Box sx={styles.dialogBox} onClick={(e) => e.stopPropagation()}>
            <Typography sx={styles.dialogTitle}>{t('4530_chat_common.new_chat_unavailable')}</Typography>
            <Typography sx={styles.dialogDescription}>{t('4530_chat_common.it_is_currently_not_possible_to_start_a_new_chat')}</Typography>

            <Box sx={styles.buttonBox}>
              <DialogButton onClick={() => setIsDialogOpened(false)} variant="outlined" color="primary" size="small">
                <Typography sx={styles.dialogButtonText}>{t('0000_common.close')}</Typography>
              </DialogButton>
            </Box>
          </Box>
        )}

        {isDisclaimerOpened && (
          <Box sx={styles.disclaimerBox}>
            <Box sx={styles.disclaimerHeader}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography sx={styles.headerTitle}>{t('4530_chat_common.disclaimer')}</Typography>
              </Box>
              <Button sx={{ padding: 0, minWidth: 0 }} onClick={handleDisclaimer}>
                <img style={{ width: '16px' }} src={icon_mini_chat_close} alt="icon_mini_chat_close" />
              </Button>
            </Box>

            <Box sx={styles.disclaimerBody}>
              <Typography sx={styles.disclamierTitle}>{t('4530_chat_common.safeguarding_your_data_in_the_hyundai_assistant')}</Typography>
              <Box sx={styles.disclamierTextBox}>
                <Typography sx={styles.disclamierTitle}>{t('4530_chat_common.how_we_process_your_data')}</Typography>
                <Typography sx={styles.disclamierText}>
                  {replaceUrlLink(t('4530_chat_common.your_privacy_is_important_to_us'), t('4530_chat_common.data_privacy_policy'), minichatUrl, {
                    linkText: styles.consentLink,
                  })}
                </Typography>
                <Typography sx={styles.disclamierText}>
                  {t('4530_chat_common.we_also_want_to_ensure_you_are_safeguarding_your_personal_information')}
                </Typography>
              </Box>
            </Box>
          </Box>
        )}

        <Box sx={styles.wrapper}>
          {!isChatStarted && (
            <>
              <Box sx={styles.header}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Typography sx={styles.headerTitle}>{t('4530_chat_common.hyundai_assistant')}</Typography>
                </Box>
                <Button sx={{ padding: 0, minWidth: 0 }} onClick={handleCloseClick}>
                  <img style={{ width: '16px' }} src={icon_mini_chat_close} alt="icon_mini_chat_close" />
                </Button>
              </Box>

              <Box sx={styles.banner}>
                <Typography sx={styles.bannerTitle}>{t('4500_chat_history_listed.hello')}</Typography>
                <Typography sx={styles.bannerTitle}>{t('4500_chat_history_listed.how_can_we_help')}</Typography>
                <Button onClick={onStartChat} sx={styles.mainButton} disabled={isLoading}>
                  <Typography sx={styles.buttonText}>{t('4500_chat_history_listed.start_a_new_chat')}</Typography>
                </Button>
              </Box>
            </>
          )}
        </Box>
        {!isChatStarted ? (
          <Box sx={styles.chatListContainer}>
            <Box sx={styles.chatListHeader}>
              <Typography sx={styles.chatListTitle}>{t('4500_chat_history_listed.chats')}</Typography>
              <Button onClick={clearHistory} sx={styles.button} disabled={chatHistoryList?.length === 0} disableRipple disableFocusRipple>
                <Typography sx={styles.clearButton} className={chatHistoryList?.length === 0 ? 'no-chat-history' : ''}>
                  {t('4500_chat_history_listed.clear_history')}
                </Typography>
              </Button>
            </Box>

            <Box sx={styles.chatListBox}>
              {isLoadingHistory && (
                <Box style={{ margin: '0 auto', padding: '95px 0' }}>
                  <LoadingSpinner />
                </Box>
              )}
              {chatHistoryList?.length !== 0 &&
                chatHistoryList?.map((chatHistory, index) => {
                  const { badge, messageData, dealer, requestStatus, requestUser } = chatHistory;
                  const dealerName = dealer?.dealerFirstName || dealer?.dealerName;
                  const chatTime = hLiveDateTimeFormat(messageData?.createDate, i18n.language);
                  let isChatBlocked = requestUser?.blockYn;

                  return (
                    <Button
                      disableRipple
                      onClick={() => handleChatClick({ requestId: messageData?.requestId, dealerInfo: dealer, requestStatus, isChatBlocked })}
                      sx={styles.chatHistoryList}
                      key={index}
                    >
                      <Box sx={styles.chatHistoryListHeader}>
                        <Typography sx={styles.chatTitle}>
                          {dealerName ? t('4500_chat_history_listed.chat_with') + ` ${dealerName}` : t('4530_chat_common.hyundai_assistant')}
                        </Typography>
                        <Typography sx={styles.chatTime}>{chatTime}</Typography>
                      </Box>
                      <Box sx={styles.chatHistoryListContents}>
                        <MessageComponent messageData={messageData} dealer={dealer} />
                        {badge ? (
                          <Badge color="error" sx={styles.unreadIcon}>
                            <Typography sx={styles.unreadText}>{badge?.unreadCount}</Typography>
                          </Badge>
                        ) : null}
                      </Box>
                    </Button>
                  );
                })}
            </Box>
          </Box>
        ) : (
          <MiniChatRoom
            isMessageListLoading={isMessageListLoading}
            setIsMessageListLoading={setIsMessageListLoading}
            requestId={requestId}
            dealerInfo={dealerInfo}
            setDealerInfo={setDealerInfo}
            requestStatus={requestStatus}
            setRequestStatus={setRequestStatus}
            setIsChatStarted={setIsChatStarted}
            handleCloseClick={handleCloseClick}
            isOffline={isOffline}
            startTime={startTime}
            endTime={endTime}
            includeBreakStartTime={includeBreakStartTime}
            includeBreakEndTime={includeBreakEndTime}
            isIncludeBreak={isIncludeBreak}
            checkIsOnline={checkIsOnline}
            messages={messages}
            setMessages={setMessages}
            handleBackClick={handleBackClick}
          />
        )}

        <Box sx={styles.footer}>
          <Button target="_blank" sx={[styles.disclaimer]} onClick={handleDisclaimer}>
            {t('4530_chat_common.privacy_info')}
          </Button>
          <Typography sx={styles.footerText}>{t('4530_chat_common.powered_by_wondermove')}</Typography>
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default MiniChat;

const checkIsOnline = (scheduleData, setStartTime, setEndTime, setIncludeBreakStartTime, setIncludeBreakEndTime, setIsIncludeBreak) => {
  const { startTime, endTime, includeBreakEndTime, includeBreakStartTime, isIncludeBreak } = scheduleData;

  setStartTime(startTime);
  setEndTime(endTime);
  setIncludeBreakStartTime(includeBreakStartTime);
  setIncludeBreakEndTime(includeBreakEndTime);
  setIsIncludeBreak(isIncludeBreak);
  const currentTime = moment().format('HH:mm');
  if (isIncludeBreak) {
    return (startTime <= currentTime && currentTime <= endTime) || (includeBreakStartTime <= currentTime && currentTime <= includeBreakEndTime);
  } else {
    return startTime <= currentTime && currentTime <= endTime;
  }

  // const currentTime = moment().format('HH:mm');
  // if (isIncludeBreak) {
  //   if (currentTime >= startTime && currentTime <= endTime) {
  //     setStartTime(startTime);
  //     setEndTime(endTime);
  //     return startTime <= currentTime && currentTime <= endTime;
  //   } else if (currentTime >= endTime && currentTime <= includeBreakStartTime) {
  //     setStartTime(endTime);
  //     setEndTime(includeBreakStartTime);
  //     return false;
  //   } else {
  //     setStartTime(startTime);
  //     setEndTime(includeBreakEndTime);
  //     return startTime <= currentTime && currentTime <= includeBreakEndTime;
  //   }
  // } else {
  //   setStartTime(startTime);
  //   setEndTime(endTime);
  //   return startTime <= currentTime && currentTime <= endTime;
  // }
};
// setStartTime(startTime);
//setEndTime(endTime);
// return startTime <= currentTime && currentTime <= endTime;
