import { styled, Box, Divider } from '@mui/material';
import { color, fonts, icon_size, width } from '@theme';

export const ContentsBox = styled(Box)(({ theme }) => ({
  width: width.width_100,
  [theme.breakpoints.down('tablet')]: {
    margin: '0 30px',
  },
  [theme.breakpoints.up('tablet')]: {
    margin: '0 60px',
  },
  [theme.breakpoints.up('laptop')]: {
    width: width.width_80,
  },
}));

export const FooterBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '20px',
  [theme.breakpoints.up('tablet')]: {
    flexDirection: 'row',
    alignItems: 'flex-end',
    gap: 0,
  },
}));

export const TermsBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '20px',
  marginTop: '20px',
  [theme.breakpoints.up('tablet')]: {
    flexDirection: 'row',
    alignItems: 'none',
    gap: 0,
  },
}));

export const TermsDivider = styled(Divider)(({ theme }) => ({
  borderColor: color.grey_800,
  margin: '0 16px',
  height: '10px',
  [theme.breakpoints.down('tablet')]: {
    border: 'none',
  },
}));

export const styles = {
  disclaimerContainer: (theme) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
    background: color.secondary_light_sand,
    padding: '50px 0',
    position: 'relative',
    [theme.breakpoints.down('tablet')]: {
      padding: '30px 20px 20px 20px',
      // height: '180px',
      // overflow: 'hidden',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
  }),

  readMoreBox: (theme) => ({
    textTransform: 'none',
    marginTop: '20px',
    padding: 0,
    display: 'none',
    '&:hover': {
      background: 'none',
    },
    [theme.breakpoints.down('tablet')]: {
      display: 'flex',
      gap: '4px',
      justifyContent: 'center',
      alignItems: 'center',
      color: color.secondary_active_blue,
    },
  }),

  footerContainer: (theme) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: color.grey_900,
    padding: '40px 0',
    [theme.breakpoints.down('tablet')]: {
      padding: '60px 0',
    },
  }),

  footerLogo: {
    border: '1px solid',
    width: '58px',
  },

  footerCopyright: {
    ...fonts.b3_text_r,
    color: color.grey_700,
  },

  disclaimerBox: (theme) => ({
    [theme.breakpoints.down('tablet')]: {
      '&.collapsed': {
        height: '130px',
        overflow: 'hidden',
      },
    },
  }),

  disclaimer: {
    ...fonts.b3_text_m,
    color: color.grey_700,
  },

  termText: {
    ...fonts.b1_text_r,
    color: color.grey_200,
  },

  disclaimerCopyright: (theme) => ({
    ...fonts.b1_text_r,
    color: color.grey_600,
    [theme.breakpoints.down('tablet')]: {
      fontSize: '14px',
      paddingRight: '10px',
    },
  }),

  footerDivider: {
    margin: '12px 0',
    borderColor: color.grey_300,
  },

  disclaimerDivider: {
    borderColor: color.grey_700,
    margin: '20px 0',
  },

  termKeyword: {
    display: 'flex',
    alignItems: 'center',
  },

  termDivider: {
    borderColor: color.grey_800,
    margin: '0 16px',
    height: '10px',
  },

  scrollButton: (theme) => ({
    width: icon_size.icon_50,
    height: icon_size.icon_50,
    [theme.breakpoints.down('tablet')]: {
      width: icon_size.icon_40,
      height: icon_size.icon_40,
    },
  }),

  countryButton: {
    ...fonts.b1_head_r,
    color: color.grey_white,
    textTransform: 'none',
    padding: 0,
    '&:hover': {
      background: 'none',
    },
    '&:active': {},
  },
  miniChatContainer: {
    position: 'fixed',
    right: '20px',
    bottom: '20px',
    zIndex: 100,
    display: 'flex',
    flexDirection: 'column-reverse',
    alignItems: 'flex-end',
  },

  miniChatButton: {
    background: color.primary_blue,
    width: '50px',
    height: '50px',
    flexShrink: 0,
    // minWidth: 'unset',
    borderRadius: '30px',
    '&:hover': { background: color.primary_hover_blue },
  },

  miniChatIcon: {
    width: '50px',
    height: '50px',
  },
};
