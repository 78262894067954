import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import { Box, Button, Dialog, DialogContent, Grid, Typography } from '@mui/material';

export const ImageViewDialog = ({ itemIndex, setItemIndex, dataList }) => {
  return (
    <Dialog
      open={itemIndex != null}
      onClose={() => setItemIndex(null)}
      fullWidth
      PaperProps={{
        sx: {
          height: 'calc(100% - 180px)',
          backgroundColor: 'transparent',
          padding: 0,
          '& img': {
            width: '100%',
            height: '100%',
            objectFit: 'contain',
          },
        },
      }}
    >
      {/* 이미지 한장만 보여주기 위한 주석 */}
      {/* <Box
        position={'fixed'}
        top={0}
        left={0}
        width={'100%'}
        sx={{
          p: 2,
        }}
      >
        <Grid container alignItems={'center'}>
          <Grid item>
            <Button variant="text" onClick={() => setItemIndex(null)}>
              <KeyboardArrowLeft
                sx={{
                  color: 'white',
                }}
              ></KeyboardArrowLeft>
            </Button>
          </Grid>
          <Grid item xs>
            <Typography variant="h6" color={'white'} textAlign={'center'}>
              {itemIndex + 1} / {dataList.length}
            </Typography>
          </Grid>
          <Grid item></Grid>
        </Grid>{' '}
      </Box> */}
      {/* <Button variant="text">
                <KeyboardArrowLeft
                  sx={{
                    color: 'white',
                  }}
                ></KeyboardArrowLeft>
              </Button> */}

      {/* <Box position={'fixed'} left={0} top={'50%'} p={3}>
        <Button disabled={itemIndex === 0} onClick={() => setItemIndex((prev) => (prev > 0 ? prev - 1 : prev))}>
          <KeyboardArrowLeft
            sx={{
              color: 'white',
            }}
          ></KeyboardArrowLeft>
        </Button>
      </Box>
      <Box position={'fixed'} right={0} top={'50%'} p={3}>
        <Button
          //   variant="circle"
          //   color={'black'}
          disabled={itemIndex === dataList.length - 1}
          onClick={() => setItemIndex((prev) => (prev < dataList.length - 1 ? prev + 1 : prev))}
        >
          <KeyboardArrowRight
            sx={{
              color: 'white',
            }}
          ></KeyboardArrowRight>
        </Button>
      </Box> */}

      <DialogContent sx={{ padding: 0, overflowY: 'clip' }}>
        <img src={dataList[itemIndex]?.src} alt="data-list" />
      </DialogContent>
    </Dialog>
  );
};
