import TagManager from 'react-gtm-module';
import { GTM_KEY } from '@constants';
import { consoleLogDev } from '@utils';

const tagManagerArgs = {
  gtmId: GTM_KEY,
};

TagManager.initialize(tagManagerArgs);

/** Hyundai Assistant */
//updata
export const gtmOpenAssistant = () => {
  const dataLayerArgs = {
    dataLayer: {
      event: 'h-live_assistant_open',
      page: { componentName: 'h-live-assistant' },
    },
  };
  TagManager.dataLayer(dataLayerArgs);
};

export const gtmCloseAssistant = () => {
  const dataLayerArgs = {
    dataLayer: {
      event: 'h-live_assistant_close',
      page: { componentName: 'h-live-assistant' },
    },
  };
  TagManager.dataLayer(dataLayerArgs);
};

export const gtmCreateMiniChat = () => {
  const dataLayerArgs = {
    dataLayer: {
      event: 'h-live_assistant_chat_start',
      page: { componentName: 'h-live-assistant' },
    },
  };
  TagManager.dataLayer(dataLayerArgs);
};

export const gtmFinishMiniChat = () => {
  const dataLayerArgs = {
    dataLayer: {
      event: 'h-live_assistant_chat_complete',
      page: { componentName: 'h-live-assistant' },
    },
  };
  TagManager.dataLayer(dataLayerArgs);
};

export const gtmOpenChatInHistory = () => {
  const dataLayerArgs = {
    dataLayer: {
      event: 'h-live_assistant_chat_restart',
      page: { componentName: 'h-live-assistant' },
    },
  };
  TagManager.dataLayer(dataLayerArgs);
};

export const gtmDownloadFile = (url, name) => {
  const dataLayerArgs = {
    dataLayer: {
      event: 'custom_file_download',
      file: {
        extension: undefined,
        name: name,
        link_classes: undefined,
        link_domain: undefined,
        link_id: undefined,
        link_text: name,
        link_url: url,
      },
      page: { componentName: 'h-live-assistant' },
    },
  };
  TagManager.dataLayer(dataLayerArgs);
};

export const gtmRateMiniChat = (qualityRating, satisfactionRating) => {
  const dataLayerArgs = {
    dataLayer: {
      event: 'h-live_assistant_rating',
      page: {
        componentName: 'h-live-assistant',
      },
      hLive: {
        rating: {
          connection: qualityRating,
          consultant: satisfactionRating,
        },
      },
    },
  };
  TagManager.dataLayer(dataLayerArgs);
};

export const gtmStartConsultChatVideo = () => {
  const dataLayerArgs = {
    dataLayer: {
      event: 'h-live_assistant_videochat_start',
      page: { componentName: 'h-live-assistant' },
    },
  };
  TagManager.dataLayer(dataLayerArgs);
};
