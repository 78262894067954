import { CONTACT, DEALERSHIP, EN, ENGLISH, H_LIVE, IT, ITALY, MODEL, POINT, SCHEDULE } from '@constants';
import { getDefaultBookingDate } from '@utils';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';

interface WorkingTime {
  active: boolean;
  open: string;
  close: string;
  lunchStart: string;
  lunchEnd: string;
}

interface BusinessHour {
  monday: WorkingTime;
  tuesday: WorkingTime;
  wednesday: WorkingTime;
  thursday: WorkingTime;
  friday: WorkingTime;
  saturday: WorkingTime;
  sunday: WorkingTime;
}

interface Holiday {
  startDate: string;
  endDate: string;
  note: string;
}

interface Position {
  type: string;
  coordinates: any[];
}

interface tempPosition {
  type: string;
  coordinates: any[];
}

interface ServiceYn {
  sales: boolean;
  service: boolean;
}

interface VehicleModelType {
  carType1: string;
  carType1Desc: string;
  carType2: string;
  carType2Desc: string;
  currency: string;
  image: string;
  modelDescription: string;
  modelId: string;
  powertrainList?: any[];
  price: number;
  price2?: number;
  sortNo: number;
}

interface DealershipType {
  _id: string;
  address: string;
  businessHour: BusinessHour;
  email: string;
  holiday: Holiday[];
  position: Position;
  name: string;
  countryCode: string;
  scheduleUnit: number;
  serviceType: ServiceYn;
  status: boolean;
  telephone: string;
  website: string;
  dealershipCode: string;
  lastModified: string;
}

interface LocationType {
  latitude: number;
  longitude: number;
}

interface CountryType {
  country: string;
  countryCode: string;
  position: tempPosition;
}

interface LanguageType {
  languageCode: string;
  language: string;
  languageContent: string;
}

interface initialState {
  userLocation: LocationType;
  selectedVehicleInfo: VehicleModelType;
  selectedDealershipInfo: DealershipType;
  // selectedBookingDate: string;
  selectedBookingDate: Date;
  selectedBookingTime: string;
  requestId: string;
  consultInfo: any;
  consultTime: any;
  userIpAddress: string;
  personalInfoFields: any[];
  externalChannelType: string;
  externalChannelLink: string;
  locationPermission: string;

  preservedData: boolean;
  setPreservedData: (preservedData: boolean) => void;

  marketingAgreement: string;
  personalizationAgreement: string;

  completed: boolean[];
  steps: string[];

  isDealershipOpen: boolean;
  isLiveConsult: boolean;

  selectedCountry: CountryType;
  countryList: CountryType[];

  selectedLanguage: LanguageType;
  languageList: LanguageType[];

  setUserLocation: (locationInfo: LocationType) => void;
  setSelectedVehicleInfo: (vehicleInfo: VehicleModelType) => void;
  setSelectedDealershipInfo: (dealershipInfo: DealershipType) => void;
  setSelectedBookingDate: (bookingDate: Date) => void;
  setSelectedBookingTime: (bookingTime: string) => void;
  setRequestId: (requestId: string) => void;
  setConsultInfo: (consultInfo: any) => void;
  setConsultTime: (consultTime: any) => void;
  setUserIpAddress: (ipInfo: string) => void;
  setPersonalInfoFields: (infoFields: any[]) => void;
  setExternalChannelType: (externalChannelType: string) => void;
  setExternalChannelLink: (externalChannelLink: string) => void;
  setLocationPermission: (locationPermission: string) => void;

  setMarketingAgreement: (marketingAgreement: string) => void;
  setPersonalizationAgreement: (personalizationAgreement: string) => void;

  resetStoreInfo: () => void;
  setCompleted: (completed: any) => void;
  setSteps: (steps: []) => void;
  setIsLiveConsult: (isLiveConsult: boolean) => void;
  setIsDealershipOpen: (isDealershipOpen: boolean) => void;

  setSelectedCountry: (countryInfo: CountryType) => void;
  setCountryList: (countryList: CountryType[]) => void;

  setSelectedLanguage: (languageInfo: LanguageType) => void;
  setLanguageList: (languageList: LanguageType[]) => void;
}

const vehicleInfoInit = {
  carType1: '',
  carType1Desc: '',
  carType2: '',
  carType2Desc: '',
  currency: '',
  image: '',
  modelDescription: '',
  modelId: '',
  powertrainList: [],
  price: 0,
  price2: 0,
  sortNo: 0,
};
const dealershipInfoInit = {
  _id: '',
  address: '',
  businessHour: {
    monday: {
      active: true,
      open: '',
      close: '',
      lunchStart: '',
      lunchEnd: '',
    },
    tuesday: {
      active: true,
      open: '',
      close: '',
      lunchStart: '',
      lunchEnd: '',
    },
    wednesday: {
      active: true,
      open: '',
      close: '',
      lunchStart: '',
      lunchEnd: '',
    },
    thursday: {
      active: true,
      open: '',
      close: '',
      lunchStart: '',
      lunchEnd: '',
    },
    friday: {
      active: true,
      open: '',
      close: '',
      lunchStart: '',
      lunchEnd: '',
    },
    saturday: {
      active: true,
      open: '',
      close: '',
      lunchStart: '',
      lunchEnd: '',
    },
    sunday: {
      active: true,
      open: '',
      close: '',
      lunchStart: '',
      lunchEnd: '',
    },
  },
  countryCode: '',
  dealershipCode: '',
  email: '',
  holiday: [],
  lastModified: '',
  name: '',
  position: {
    type: '',
    coordinates: [],
  },

  scheduleUnit: 0,
  serviceType: {
    sales: false,
    service: false,
  },
  status: false,
  telephone: '',
  website: '',
};

const bookingStore = create(
  persist<initialState>(
    (set) => ({
      isLiveConsult: false,
      setIsLiveConsult: (isLiveConsult) =>
        set(() => ({
          isLiveConsult,
        })),

      isDealershipOpen: false,
      setIsDealershipOpen: (isDealershipOpen) =>
        set(() => ({
          isDealershipOpen,
        })),

      resetStoreInfo: () =>
        set(() => ({
          completed: [false, false, false, false],
          steps: [MODEL, DEALERSHIP, SCHEDULE, CONTACT],
          selectedVehicleInfo: vehicleInfoInit,
          selectedDealershipInfo: dealershipInfoInit,
          selectedBookingDate: getDefaultBookingDate(),
          selectedBookingTime: '',
          consultInfo: {},
          isLiveConsult: false,
          preservedData: false,
        })),

      userLocation: { latitude: null, longitude: null },
      setUserLocation: (userLocation) => set(() => ({ userLocation })),

      selectedVehicleInfo: {
        carType1: '',
        carType1Desc: '',
        carType2: '',
        carType2Desc: '',
        currency: '',
        image: '',
        modelDescription: '',
        modelId: '',
        powertrainList: [],
        price: 0,
        price2: 0,
        sortNo: 0,
      },
      setSelectedVehicleInfo: (vehicleInfo) => set(() => ({ selectedVehicleInfo: vehicleInfo })),

      selectedDealershipInfo: {
        _id: '',
        address: '',
        businessHour: {
          monday: {
            active: true,
            open: '',
            close: '',
            lunchStart: '',
            lunchEnd: '',
          },
          tuesday: {
            active: true,
            open: '',
            close: '',
            lunchStart: '',
            lunchEnd: '',
          },
          wednesday: {
            active: true,
            open: '',
            close: '',
            lunchStart: '',
            lunchEnd: '',
          },
          thursday: {
            active: true,
            open: '',
            close: '',
            lunchStart: '',
            lunchEnd: '',
          },
          friday: {
            active: true,
            open: '',
            close: '',
            lunchStart: '',
            lunchEnd: '',
          },
          saturday: {
            active: true,
            open: '',
            close: '',
            lunchStart: '',
            lunchEnd: '',
          },
          sunday: {
            active: true,
            open: '',
            close: '',
            lunchStart: '',
            lunchEnd: '',
          },
        },
        countryCode: '',
        name: '',
        dealershipCode: '',
        email: '',
        holiday: [],
        lastModified: '',
        position: {
          type: '',
          coordinates: [],
        },

        scheduleUnit: 0,
        serviceType: {
          sales: false,
          service: false,
        },
        status: false,
        telephone: '',
        website: '',
      },
      setSelectedDealershipInfo: (dealershipInfo) => set(() => ({ selectedDealershipInfo: dealershipInfo })),

      selectedBookingDate: getDefaultBookingDate(),
      selectedBookingTime: '',
      setSelectedBookingDate: (bookingDateInfo) => set(() => ({ selectedBookingDate: bookingDateInfo })),
      setSelectedBookingTime: (bookingTimeInfo) => set(() => ({ selectedBookingTime: bookingTimeInfo })),

      marketingAgreement: null,
      setMarketingAgreement: (marketingAgreement) => set(() => ({ marketingAgreement })),

      personalizationAgreement: null,
      setPersonalizationAgreement: (personalizationAgreement) => set(() => ({ personalizationAgreement })),

      requestId: '',
      setRequestId: (id) => set(() => ({ requestId: id })),

      consultInfo: {},
      setConsultInfo: (info) => set(() => ({ consultInfo: info })),

      consultTime: '',
      setConsultTime: (time) => set(() => ({ consultTime: time })),

      userIpAddress: '',
      setUserIpAddress: (userIpAddress) => set(() => ({ userIpAddress })),

      personalInfoFields: [],
      setPersonalInfoFields: (infoFields) =>
        set(() => ({
          personalInfoFields: infoFields,
        })),

      countryList: [],
      setCountryList: (countryList) => set(() => ({ countryList: countryList })),

      selectedCountry: {
        country: '',
        countryCode: '',
        position: {
          type: POINT,
          coordinates: [],
        },
      },

      setSelectedCountry: (selectedCountry) => set(() => ({ selectedCountry })),

      languageList: [],
      setLanguageList: (languageList) => set(() => ({ languageList })),

      selectedLanguage: {
        languageCode: EN,
        language: ENGLISH,
        languageContent: ENGLISH,
      },
      setSelectedLanguage: (selectedLanguage) => set(() => ({ selectedLanguage })),

      externalChannelType: H_LIVE,
      setExternalChannelType: (externalChannelType) => set(() => ({ externalChannelType })),

      externalChannelLink: H_LIVE,
      setExternalChannelLink: (externalChannelLink) => set(() => ({ externalChannelLink })),

      locationPermission: '',
      setLocationPermission: (locationPermission) => set(() => ({ locationPermission })),

      completed: [false, false, false, false],
      setCompleted: (completed) => set(() => ({ completed: completed })),

      steps: [MODEL, DEALERSHIP, SCHEDULE, CONTACT],
      setSteps: (steps) => set(() => ({ steps })),

      preservedData: false,
      setPreservedData: (preservedData) =>
        set(() => ({
          preservedData,
        })),
    }),
    {
      name: 'booking-store',
    },
  ),
);

export default bookingStore;
