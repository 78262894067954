import { DECRYPT_FIRST_KEY, DECRYPT_SECOND_KEY, DENIED, DEV, PROD, EN, GRANTED, GTM_KEY, NODE_ENV, UNAVAILABLE, WEEKDAYS } from '@constants';
import { Link, useMediaQuery } from '@mui/material';
import * as CryptoJS from 'crypto-js';
import moment from 'moment';
// import chatAlarm from '@audios/chatAlarm.mp3';
import ReactGA from 'react-ga';
import TagManager from 'react-gtm-module';

export const consoleLogDev = (message, variable) => {
  if (NODE_ENV !== PROD) {
    variable !== undefined ? console.log(`${message}:`, variable) : console.log(message);
  }
};

export const logApiResponse = (apiFunction, response) => {
  NODE_ENV !== PROD && console.log(`[API SUCCESS] ${apiFunction}:`, response);
};

export const logApiError = (apiFunction, error) => {
  NODE_ENV !== PROD && console.error(`[API ERROR] ${apiFunction}:`, error);
};

export const IsLaptop = (theme) => {
  return useMediaQuery(theme.breakpoints.down('desktop'));
};

export const IsTablet = (theme) => {
  return useMediaQuery(theme.breakpoints.down('laptop'));
};

export const IsMobile = (theme) => {
  return useMediaQuery(theme.breakpoints.down('tablet'));
};

export const replaceUrlLink = (stringText, replaceText, privacyPolicyUrl, styles) => {
  const policyLink = (
    <>
      {stringText.split('$')[0]}
      <Link sx={{ ...styles.linkText }} target="_blank" href={privacyPolicyUrl}>
        {replaceText}
      </Link>
      {stringText.split('$')[1]}
    </>
  );
  return policyLink;
};

export const encryptData = async (data) => {
  const firstSaltKey = DECRYPT_FIRST_KEY;
  const secondSaltKey = DECRYPT_SECOND_KEY.substring(4, 17);
  const cryptoTest = CryptoJS.AES.encrypt(JSON.stringify(data), firstSaltKey).toString();
  const encryptAgain = CryptoJS.AES.encrypt(cryptoTest, secondSaltKey).toString();
  return encryptAgain;
};

export const getDefaultBookingDate = () => {
  const currentDate = moment();
  if (currentDate.day() === 6) {
    currentDate.add(2, 'days');
  } else {
    currentDate.add(1, 'days');
  }
  return currentDate.toDate();
};

export const getNextBookingDate = (holidayArray) => {
  const isHoliday = (date, holidays) => {
    return holidays.some((holiday) => {
      const startDate = moment.utc(holiday.startDate);
      const endDate = moment.utc(holiday.endDate);
      const result = date.isBetween(startDate, endDate, 'day', '[]');
      return result;
    });
  };
  const today = moment.utc();
  let tomorrow = moment.utc(today).add(1, 'day');

  while (isHoliday(tomorrow, holidayArray)) {
    tomorrow = moment(tomorrow).add(1, 'day');
    consoleLogDev('🚀 ~ file: utils.js:42 ~ getNextBookingDate ~ tomorrow:', tomorrow);
  }
  if (tomorrow.day() === 0) {
    tomorrow.add(1, 'day');
  }
  return tomorrow;
};

export const getMediaPermission = () => {
  return new Promise((resolve, reject) => {
    navigator.mediaDevices
      .getUserMedia({ video: true, audio: true })
      .then((stream) => {
        if (stream.getVideoTracks().length > 0 && stream.getAudioTracks().length > 0) {
          resolve(GRANTED);
          consoleLogDev('getMediaPermission: granted');
        } else {
          resolve(DENIED);
          consoleLogDev('getMediaPermission: denied');
        }
      })
      .catch((error) => {
        // User denied permission or an error occurred
        if (error) {
          console.error(error);
          resolve(DENIED);
        } else {
          reject(error);
        }
      });
  });
};

export const getUserLocation = async (setUserLocation, defaultLocation, setLocationPermission) => {
  const options = {
    enableHighAccuracy: false,
    timeout: Infinity,
    maximumAge: 5000,
  };

  try {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const centerLat = parseFloat(position?.coords?.latitude);
          const centerLng = parseFloat(position?.coords?.longitude);
          setLocationPermission(GRANTED);
          setUserLocation({ latitude: centerLat, longitude: centerLng });
        },
        (error) => {
          if (error.code === 1 || error.code === 2) {
            setLocationPermission(DENIED);
            setUserLocation({ latitude: defaultLocation?.coordinates[1], longitude: defaultLocation?.coordinates[0] });
          }
        },
        options,
      );
    } else {
      consoleLogDev('Geolocation not supported on this device or OS');
      setLocationPermission(DENIED);
      setUserLocation({ latitude: defaultLocation?.coordinates[1], longitude: defaultLocation?.coordinates[0] });
    }
  } catch (e) {
    console.error(e);
  }
};

// export const getExternalIpAddress = async () => {
//   try {
//     const response = await axios.get('https://geolocation-db.com/json/');
//     const externalIPAddress = response?.data?.IPv4;
//     return externalIPAddress;
//   } catch (error) {
//     consoleLogDev('Error:', error.message);
//   }
// };

export const getInternalIpAddress = async () => {
  try {
    const internalIPAddress = await new Promise((resolve, reject) => {
      const ipPC = new RTCPeerConnection({ iceServers: [] });

      ipPC.createDataChannel('');
      ipPC
        .createOffer()
        .then((offer) => ipPC.setLocalDescription(offer))
        .catch(reject);

      ipPC.onicecandidate = (event) => {
        if (event.candidate) {
          const ipAddress = event.candidate.candidate.split(' ')[4];
          resolve(ipAddress);
          ipPC.close();
        }
      };
    });
    return internalIPAddress;
  } catch (error) {
    console.error(error);
  }
};

export const inputValidation = (value) => {
  if (value === '') {
    return true;
  } else {
    return false;
  }
};

export const emailValidation = (value) => {
  const regexp = /^[A-Za-z0-9._%+-][A-Za-z0-9._%+-]{1,62}@[A-Za-z0-9.-]{2,63}\.[A-Za-z]{2,63}$/;
  if (!value.match(regexp)) {
    return true;
  } else {
    return false;
  }
};

export const userPhoneNumberValidation = (value) => {
  const regexp = new RegExp(/[0-9]{9,14}/g);
  if (!regexp.test(value)) {
    return true;
  } else {
    return false;
  }
};

export const postCodeValidation = (value) => {
  const regexp = new RegExp(/[0-9]{9,14}/g);
  if (!regexp.test(value)) {
    return true;
  } else {
    return false;
  }
};

export const workingHourFilter = (dealershipInfo) => {
  if (dealershipInfo?.businessHour === UNAVAILABLE) {
    return UNAVAILABLE;
  }

  let flag = false;
  const dayOfWeek = moment().weekday();
  const currentDate = moment().format('YYYY-MM-DD');
  const currentTime = moment().format('YYYY-MM-DD HH:mm');

  let isHoliday = !dealershipInfo?.businessHour[WEEKDAYS[dayOfWeek]]?.active;
  dealershipInfo?.holiday?.forEach((i) => {
    let startDate = moment(i.startDate, 'MM/DD/YYYY').utc();
    let endDate = moment(i.endDate, 'MM/DD/YYYY').utc();
    if ((startDate.isSame(currentDate) || startDate.isBefore(currentDate)) && (endDate.isSame(currentDate) || endDate.isAfter(currentDate))) {
      //holiday 가 아니면 true로 변경해야함
      // flag = true;
      isHoliday = true;
    }
  });
  if (isHoliday) return false;

  const openHour = dealershipInfo?.businessHour[WEEKDAYS[dayOfWeek]]?.open?.substring(0, 2);
  const openMinute = dealershipInfo?.businessHour[WEEKDAYS[dayOfWeek]]?.open?.substring(3, 5);

  const closeHour = dealershipInfo?.businessHour[WEEKDAYS[dayOfWeek]]?.close?.substring(0, 2);
  const closeMinute = dealershipInfo?.businessHour[WEEKDAYS[dayOfWeek]]?.close?.substring(3, 5);

  const lunchStartHour = dealershipInfo?.businessHour[WEEKDAYS[dayOfWeek]]?.lunchStart?.substring(0, 2);
  const lunchStartMinute = dealershipInfo?.businessHour[WEEKDAYS[dayOfWeek]]?.lunchStart?.substring(3, 5);

  const lunchEndHour = dealershipInfo?.businessHour[WEEKDAYS[dayOfWeek]]?.lunchEnd?.substring(0, 2);
  const lunchEndMinute = dealershipInfo?.businessHour[WEEKDAYS[dayOfWeek]]?.lunchEnd?.substring(3, 5);

  const openTime = moment().hour(openHour).minute(openMinute).format('YYYY-MM-DD HH:mm');
  const closeTime = moment().hour(closeHour).minute(closeMinute).format('YYYY-MM-DD HH:mm');
  const lunchStart = moment().hour(lunchStartHour).minute(lunchStartMinute).format('YYYY-MM-DD HH:mm');
  const lunchEnd = moment().hour(lunchEndHour).minute(lunchEndMinute).format('YYYY-MM-DD HH:mm');

  if (dealershipInfo.businessHour[WEEKDAYS[dayOfWeek]]?.active) {
    if (!dealershipInfo.businessHour[WEEKDAYS[dayOfWeek]]?.includeBreakHour) {
      if (openTime <= currentTime && currentTime <= closeTime) {
        flag = true;
      }
    } else if ((openTime <= currentTime && currentTime <= lunchStart) || (lunchEnd <= currentTime && currentTime <= closeTime)) {
      flag = true;
    }
  } else {
    flag = false;
  }

  return flag;
};

export const scrollTop = () => {
  window.scroll({
    top: 0,
    behavior: 'smooth',
  });
};

// export const turnOnAlarm = () => {
//   const audio = new Audio('https://hlivedev.blob.core.windows.net/hlive-button-script/static/media/shortAlarm.9db133c3a91d5be8f060.mp3');
//   audio.play();
//   audio.addEventListener('ended', () => {
//     audio.remove();
//   });
// };

//playPromise check
export const turnOnAlarm = () => {
  const audio = new Audio('https://hlivedev.blob.core.windows.net/hlive-button-script/static/media/shortAlarm.9db133c3a91d5be8f060.mp3');

  audio.addEventListener('error', (event) => {
    console.error('Error while playing audio:', event.target.error);
  });

  const playPromise = audio.play();

  if (playPromise !== undefined) {
    playPromise
      .then((_) => {})
      .catch((error) => {
        console.error('Error while playing audio:', error);
      });
  }

  audio.addEventListener('ended', () => {
    audio.remove();
  });
};

export const displayString = (stringText, replaceString) => stringText.replace('$', replaceString);

const momentInstance = moment.utc;

export const commonDateFormat = (date, languageCode = EN) => {
  if (languageCode === 'no') languageCode = 'nb';
  return momentInstance(date).local().locale(languageCode).format('DD.MM.YYYY');
};
export const commonTimeFormat = (date, languageCode = EN) => {
  if (languageCode === 'no') languageCode = 'nb';
  // let diff = momentInstance().local().locale(languageCode).diff(date, 'days');
  // return diff ? commonDateFormat(date, languageCode) : momentInstance(date).local().locale(languageCode).format('ddd HH:mm A');
  return momentInstance(date).local().locale(languageCode).format('ddd HH:mm A');
};

export const commonDateTimeFormat = (date, languageCode = EN) => {
  if (languageCode === 'no') languageCode = 'nb';
  return momentInstance(date).local().locale(languageCode).format('DD/MM/YYYY ddd HH:mm');
};

export const hLiveDateTimeFormat = (date, languageCode = EN) => {
  if (languageCode === 'no') languageCode = 'nb';
  return momentInstance(date).local().locale(languageCode).format('HH:mm A');
};

export const commonInputDateFormat = (date) => {
  return momentInstance(date).local().format('DD/MM/YYYY');
};

export const fromNowFormat = (date, languageCode = EN) => {
  if (languageCode === 'no') languageCode = 'nb';
  let diff = momentInstance().local().locale(languageCode).diff(date, 'days');

  return diff ? commonDateFormat(date, languageCode) : momentInstance(date).local().locale(languageCode).fromNow();
};

export const getPreselectTime = (timeSlotArray, dealershipInfo, bookingDate, bookingTime) => {
  const currentTime = moment().format('YYYY-MM-DD HH:mm');
  const dayOfWeek = moment().weekday();

  const openHour = dealershipInfo?.businessHour[WEEKDAYS[dayOfWeek]]?.open.substring(0, 2);
  const openMinute = dealershipInfo?.businessHour[WEEKDAYS[dayOfWeek]]?.open.substring(3, 5);

  const closeHour = dealershipInfo?.businessHour[WEEKDAYS[dayOfWeek]]?.close.substring(0, 2);
  const closeMinute = dealershipInfo?.businessHour[WEEKDAYS[dayOfWeek]]?.close.substring(3, 5);

  const openTime = moment().hour(openHour).minute(openMinute).format('YYYY-MM-DD HH:mm');
  const closeTime = moment().hour(closeHour).minute(closeMinute).format('YYYY-MM-DD HH:mm');

  const nextDate = moment(currentTime).add(1, 'day').toDate();
  const newBookingDate = moment(bookingDate).format('YYYY-MM-DD');
  const newNextDate = moment(nextDate).format('YYYY-MM-DD');

  if (bookingTime === '') {
    if (newNextDate === newBookingDate && (currentTime > closeTime || currentTime < openTime)) {
      for (let i = 0; i < timeSlotArray?.length; i++) {
        if (timeSlotArray[i].time >= '09:00' && timeSlotArray[i].time <= '12:00') {
          timeSlotArray[i].isAble = false;
        }
      }
      const firstAvailableTime = timeSlotArray?.find((obj) => obj.isAble);
      // setSelectedBookingTime(firstAvailableTime?.time);
      return firstAvailableTime?.time;
    } else {
      const firstAvailableTime = timeSlotArray?.find((obj) => obj.isAble);
      // setSelectedBookingTime(firstAvailableTime?.time);
      return firstAvailableTime?.time;
    }
  }
};
