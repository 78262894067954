import Lottie from 'lottie-react';
import lottie_spinner from '@lotties/lottie_spinner.json';
import { Box, Modal } from '@mui/material';
import { styles } from './styles';

export default function LoadingSpinner() {
  return (
    <Box sx={styles.spinnerBox}>
      <Lottie style={styles.spinnerLottie} animationData={lottie_spinner} loop={true} />
    </Box>
  );
}
