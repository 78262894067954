export default {
  translation: {
    '0000_common': {
      hyundai: 'Hyundai',
      live_chat: 'Live Chat.',
      language: 'Language',
      purchase_your_next_hyundai_from_the_comfort_of_your_own_home: 'Purchase your next Hyundai from the comfort of your own home.',

      model: 'Model',
      dealership: 'Dealership',
      schedule: 'Schedule',
      contact: 'Contact details',
      real_time: 'Real-time',

      copyright_2023_hyundai_motor_company_all_rights_reserved: '© Copyright 2023 Hyundai Motor Company. All rights reserved',
      the_information_on_this_site_relating_to_hyundai_motor_company:
        'The information on this site relating to Hyundai Motor Company and its products is provided for information only without any express or implied warranty of any kind. The information published may contain direct or indirect references even to products not available in your country. For details about the products available in your country, contact the nearest dealer directly.',

      imprint: 'Imprint',
      privacy_policy: 'Privacy Policy',
      disclaimer: 'Disclaimer',
      cookie: 'Cookies',
      click_here: 'click here',
      return_to_home: 'Return to home',

      almost_there: 'Almost there!',
      to_ensure_a_seamless_video_call_experience:
        'To ensure a seamless video call experience, we kindly ask for your permission to access your camera and microphone. Please rest assured that your camera feed will remain private and visible only to you.',
      please_allow_us_to_access_your_camera_and_microphone_for_a_seamless_experience:
        'Please allow us to access your camera and microphone for a seamless experience.',
      but_do_not_worry_this_step_is_only_required_for_establishing_the_connection:
        "But don't worry, this step is only required for establishing the connection, not for utilising your camera.",

      select: 'Select',
      confirm: 'Confirm',
      close: 'Close',
      request: 'Request',
      next: 'Next',
      retry: 'Retry',
      end_call: 'End call',
      stay_in_call: 'Stay in call',
      edit: 'Edit',
      submit: 'Submit',
      home: 'Home',
      start: 'Start',
      accept: 'Accept',
      available: 'Available',
      unavailable: 'Unavailable',
      cancelled: 'Cancelled',
      requesting: 'Requesting',
      yes_start_again: 'Yes, start again.',
      request_failed: 'Request failed.',
      an_error_has_occurred_Please_try_again: 'Oops. Sorry for that. An error has occurred. Please try again.',
      your_session_has_timed_out: 'Your session has timed out.',
      network_error: 'Network error',
      the_network_connection_has_been_lost: 'Opps. The network connection has been lost.',
      please_click_the_retry_button_to_connect_again: 'Please click the retry button to connect again.',
      read_more: 'Read more',
      requesting_live_consultation: 'Requesting Live Consultation.',
      booking_date: 'Booking date',
      book_an_appointment: 'Book an appointment',
      join_live_consultation: 'Join the meeting.',

      name: 'Name',
      email: 'Email',
      mobile: 'Mobile',
      address: 'Address',
      mail: 'Mail',
      phone: 'Phone',
      comments: 'Comment',
      uploading: 'Uploading',
      asterisk: '*',
      none: 'None',
    },

    '0200_country': {
      europe: 'Europe',
      enter_the_name_of_your_country_here: 'Enter the name of your country here.',
      if_you_change_the_country_your_saved_data_will_be_reset: 'If you click confirm, your saved data will be reset.',
      would_you_like_to_change_country: 'Would you like to change the selected country?',

      sorry_there_are_no_result: 'Sorry, but there are no results for that entry.',
      please_try_again_enter_the_name_of_the_country:
        'Please try again. Enter the name of the country you are looking for in the search field above.',
    },

    '0031_cookie': {
      we_are_using_cookies: 'We are using Cookies.',
      we_use_cookies_to_analyze:
        'We use cookies to analyze our services and to show ads that are relevant to you based on your browsing habits. You can decline or set your preferred configuration by clicking Configure. For more information $.',
      accept_all: 'Accept all',
      configure: 'Configure',
      accept_only_technical_cookies: 'Accept only technical cookies',
    },

    '0032_privacy_policy': {
      personalizing_your_hyundai_experience: 'Personalizing your Hyundai Experience',
      i_want_to_receive_marketing_communication_and_get_updates_from_hyundai_to_stay_informed_about_hyundai_products_services_events_and_offers:
        'I want to receive marketing communication and get updates from Hyundai to stay informed about Hyundai products services, events and offers.',
      i_want_to_receive_personalized_communication_based_on_my_preferences_and_behaviors_as_well_as_my_use_of_Hyundai_products_and_services:
        'I want to receive personalized communication based on my preferences and behaviors as well as my use of Hyundai products and services.',
      i_understand_i_can_amend_or_withdraw_my_consent_to_the_processing_of_my_personal_data_at_any_time:
        'I understand I can amend or withdraw my consent to the processing of my personal data at any time.',
      for_more_information_about_the_processing_of_my_personal_data_and_my_rights_I_can_refer_to_the:
        'For more information about the processing of my personal data and my rights, I can refer to the $.',
      by_requesting_marketing_communication:
        'By requesting marketing communication, you agree that Hyundai Motor Italy processes your personal data to send you communication and updates about products, services, events and offers.',
      to_send_you_marketing_communication:
        'To send you marketing communication, we may contact you via email, phone, mail and/or messenger services (SMS, messengers services or online chat).',
      hyundai_motor_italy_may_also_transit_your_individual_customer_profile:
        'Hyundai Motor Italy may also transit your individual customer profile to Hyundai companies and partners and Hyundai’s authorized network retailers and workshops (either your selected preferred dealer or regional dealer close to you), who use your personal data for the same purposes and may send marketing communication to you.',
      by_requesting_personalized_communication:
        'By requesting personalized communication, you agree that Hyundai Italy processes your personal data to send you communication that is relevant for you and to create an individual customer profile.',
      to_send_you_personalized_communication:
        'To send you personalized communication, we may contact you via email, phone, mail and/or messenger services (SMS, messengers services or online chat).',
      to_create_an_individual_customer_profile:
        'To create an individual customer profile we will use appropriate mathematical and statistical procedures to analyze your preferences and interests as well as your interaction with Hyundai products and services.',
      hyundai_italy_may_also_transmit_your_individual_customer_profile:
        "Hyundai Italy may also transmit your individual customer profile to Hyundai companies and partners and Hyundai's authorized network of retailers and workshops (either your selected preferred dealer or a regional dealer close to you), who use your personal data for the same purposes and may send communication that is relevant or you.",
      your_preferences: 'Your Preferences',
      you_can_edit_online_how_much_of_Hyundai_you_want_in_your_life: 'You can edit online how much of Hyundai you want in your life.',
      click_on_the_unsubscribe_link_in_every_communication_we_send_you: '• Click on the unsubscribe link in every communication we send you',
      please_note:
        'Please note: The withdrawal or editing of your of consent does not affect the lawfulness of processing based on the consent before its withdrawal or editing.',
    },

    '0090_error': {
      404: '404',
      500: '500',
      502: '502',
      page_not_found: 'Page not found',
      internal_server_error: 'Internal server error',
      bad_gateway: 'Bad gateway',
      the_requested_page_does_not_exist: 'The requested page does not exist.',
      an_error_has_occured_please_try_again: 'An error has occured. Please try again.',
    },

    '0000_vehicle_model': {
      select_a_model: 'Select a model.',
    },

    '0100_dealership': {
      select_a_dealership: 'Select a dealership.',
      search_by_postcode_dealership_city: 'Search by Postcode, Dealership or City',
      business_hour: 'Business hours',
      open: 'Open',
      closed: 'Closed',
      closed_appointment_only: 'Closed (Appointment only)',
      no_matching_result_please_try_again: 'No matching result. Please try again.',
      total_result: 'Total result',
      to_enable_search_please_allow_us_to_access_your_location: 'To enable search, please allow us to access your location.',
      looks_like_the_dealer_is_busy_and_is_unable_to_respond_to_your_request_at_this_time:
        'Looks like the dealer is busy and is unable to respond to your request at this time.',
    },

    '0200_schedule': {
      schedule_appointment: 'Schedule Appointment.',
      make_an_appointment_or_have_a_Live_consultation_right_now: 'Make an appointment or Have a Live Consultation right now.',
      would_you_like_to_have_a_live_consultation_right_now: 'Would you like to have a Live Consultation right now?',
      select_time: 'Select Time',
      request_now: 'Request Now',
      request_real_time_live_consult: 'Request real-time Live Consultation.',
      the_time_you_have_selected_is_not_available: "The time you've selected is not available.",
      please_try_again_by_selecting_another_time_slot_or_date: 'Please try again by selecting another time slot or date.',
    },

    '0300_contact': {
      please_enter_your_contact_information: 'Please enter your contact information.',
      first_name: 'First Name',
      last_name: 'Last Name',
      street_address: 'Street Address',
      apartment_suite_unit_etc: 'Apartment, Suite, Unit etc',
      zip_postalcode: 'Zip / Postalcode',
      city: 'City',
      mandatory_field: 'Mandatory Field',
      do_you_have_any_additional_comments_or_requests: 'Do you have any additional comments or requests?',
      marketing_activities: 'Marketing activities',
      profiling_activity: 'Profiling activity',

      please_take_a_moment_to_review_this_information_and_be_ready_to_start_your_experience:
        'Please take a moment to review this information and be ready to start your experience.',

      please_select_an_option: 'Please select an option',
      enter_your_first_name: 'Please enter your first name.',
      enter_your_last_name: 'Please enter your last name.',
      email_format_incorrect: 'Please check the spelling of your email address, it seems there is something missing.',
      enter_a_valid_mobile_number_with_numbers_only: 'Please enter a valid mobile number using numbers only, no symbols.',
      enter_your_street_address: 'Please enter your street address.',
      enter_your_city: 'Please enter your city.',
      enter_your_postal_code: 'Please enter your postal code.',
    },

    '0430_Confirmation': {
      thank_you: 'Great. Thank you.',
      you_will_also_a_receive_a_copy_of_your_reservation_request_to_your_email:
        'You will also a receive a copy of your reservation request to your email.',
      on_the_day_of_your_appointment_you_will_also_receive_a_reminder_via_email:
        'On the day of your appointment, you will also receive a reminder via email.',
      request_summary: 'Here is a summary of your request.',
      date_time: 'Date / Time',
      address: 'Address, City, State',
      telephone: 'Telephone',
      comments: 'Comment',
    },

    '0400_requesting_live_consult': {
      requesting_live_consultation: 'Requesting Live Consultation.',
      remaining_time_is: 'Remaining time is',
      seconds: 'seconds',
      would_you_like_to_cancel_the_live_consult_and_book_an_appointment_instead:
        'Would you like to cancel the live consultation and book an appointment instead?',
    },

    '0410_counsultation_booking': {
      close_live_consultation: 'Close Live Consultation.',
      personal_information: 'Personal Information',
      new_appontment_request_sent: 'New appointment request sent.',
      your_appointment_request_has_been_sent_to_your_chosen_dealership: 'Your appointment request has been sent to your chosen dealership.',
      you_will_receive_an_email_with_the_details_of_your_request: 'You will receive an email with the details of your request.',
      do_you_want_to_end_your_live_consultation: 'Do you want to end your Live Consultation?',
    },

    '0440_live_cancelled': {
      time_expired: 'Time expired',
      sorry_that_did_not_go_through: "Sorry, that didn't go through.",
      looks_like_the_dealer_is_busy_and_is_unable_to_respond_to_your_request_at_this_time:
        'Looks like the dealer is busy and is unable to respond to your request at this time.',
      but_you_can_book_an_appointment_for_another_time_at_your_convenience: 'But you can book an appointment for another time at your convenience.',
      live_consultation_cancelled: 'Live Consultation Cancelled',
    },

    '0450_pre_entered': {
      hello_and_welcome: 'Hello and welcome.',
      you_are_a_bit_early: "Oops, You're a bit early. You might want to come back at the time shown below.",
      sorry_that_did_not_go_through: "Sorry, that didn't go through.",
      if_you_wish_to_change_or_cancel_your_reservation: 'If you wish to change or cancel your reservation, please contact your dealership',
      please_contact_the_dealership_directly_or_book_a_new_live_consultation:
        'Please contact the dealership directly or book a new Live Consultation.',
      days_left: 'days left',
    },

    '0500_live_consult': {
      live_consultation_ended: 'Live Consultation ended.',
      camera_off: 'Camera off',
      connecting_video: 'Connecting video...',
      mic_off: 'Microphone is off',

      please_enter_a_chat: 'Please enter your chat message here.',
      dealer_has_joined: ' has joined the Meeting.',
      now_you_can_start_the_conversation: 'Now you can start the conversation.',

      download_brochure: 'Download Brochure',
      live_chat_ended: 'Live Chat Ended',
      click_end_call_to_complete_the_call: "Click 'End call' to complete the call.",
      do_you_want_to_end_your_Live_Consultation: 'Do you want to end your Live Consultation?',
      dealer_has_ended_the_call: ' has ended the Meeting.',
    },

    '0600_rating': {
      thank_you_for_joining: 'Thank you for joining.',
      rate_your_experience: 'Rate your experience on your live consultation.',
      how_was_the_quality_of_the_connection: 'How was the quality of the connection during your live consultation?',
      how_satisfied_are_you_with_the_consultation: 'How satisfied are you with the consultation you received?',
      thank_you_for_sharing_your_thoughts: 'Thank you for sharing your thoughts with us!',
      your_rating_has_been_successfully_sent: 'Your rating has been successfully sent.',
      click_the_button_below_to_return_to_home: 'Click the button below to return to home.',
      anything_else_that_you_would_like_to_say: 'Anything else that you would like to say?',
      brochure: 'Brochure',
    },

    '4500_chat_history_listed': {
      hello: 'Hei. ',
      how_can_we_help: 'hva kan jeg hjelpe deg med?',
      start_a_new_chat: 'Start en ny chat',
      chats: 'Chats',
      clear_history: 'Slett historikk',
      chat_with: 'Konsulent er',
      help_desk: 'Hyundai assistent',
    },

    '4510_chat_in_progress': {
      welcome_to_hyundai_assistant: 'Velkommen til Hyundai Assistant.',
      feel_free_to_leave_us_a_message: 'Legg gjerne inn en melding og',
      we_will_get_back_to_you_shortly: ' vi vil ta kontakt i løpet av kort tid.',
    },

    '4511_chat_unavailable': {
      sorry_as_it_is_outside_of_business_hours: 'Ingen konsulenter tilgjengelig for øyeblikket.',
      chat_available_time: 'Tilgjengelig tid: ',
      this_service_is_currently_unavailable_for_the_public_holiday:
        'Denne tjenesten er ikke tilgjengelig i dag. Prøv igjen en annen dag. Ha en riktig fin dag.',
      this_service_is_currently_unavailable: 'Denne tjenesten er ikke tilgjengelig i dag. ',
      we_be_back_soon: 'Prøv igjen en annen dag.',
      thank_you_for_your_patience: 'Ha en riktig fin dag.',
    },

    '4520_chat_ratings': {
      live_chat_ended: 'LIVE Chat er avsluttet',
      rate_your_experience: 'Del in opplevelse med H-LIVE.',
      how_was_the_quality_of_the_connection: 'Hvor raskt fikk du svar på din forespørsel?',
      how_satisfied_are_you_with_the_consultation: 'Hvor fornøyd er du med konsultasjonen du hadde?',
      submit: 'Send inn',
      thank_you_for_sharing_your_experience: 'Takk for at du deler din vurdering med oss.',
    },

    '4530_chat_common': {
      hyundai_assistant: 'Hyundai Assistant',
      disclaimer: 'Disclaimer',
      privacy: 'Privacy',
      // no
      new_chat_started: 'New chat started',
      consultant_has_joined_the_chat: 'har blitt med i samtalen',
      consultant_has_changed: 'Ny konsulent',

      chat_ended: 'Chatten er avsluttet',
      the_chat_has_been_ended_by_the_administrator: 'Chatten har blitt avsluttet av operatøren.',
      privacy_info: 'Ansvarsfraskrivelse',
      powered_by_wondermove: 'Powered by.wondermove',
      please_enter_a_chat: 'Skriv inn melding her',
      new_chat_unavailable: 'Ny Chat er utilgjengelig',
      it_is_currently_not_possible_to_start_a_new_chat: 'Kan ikke starte en ny chat.',

      safeguarding_your_data_in_the_hyundai_assistant: 'Full kontroll på dine data i Hyundai assistenten.',
      how_we_process_your_data: 'Hvordan behandler vi dine data',
      your_privacy_is_important_to_us:
        'Sikkerheten for dine data er viktig for oss. Når du interagerer med oss i chatten kan vi samle inn og behandle personlig informasjon for å kunne gi deg best mulig service og oppfølging. For å gjøre dette så trygt som mulig har vi strenge sikkerhetstiltak på plass for å beskytte dataene dine. For nærmere opplysninger om hvordan vi behandler data ber vi deg lese $.',
      data_privacy_policy: 'gjennom vår personvernerklæring',
      we_also_want_to_ensure_you_are_safeguarding_your_personal_information:
        'Vi ønsker også å sikre din personlige informasjon. Vi ber deg derfor kun om å dele nødvendig informasjon slik at vi kan hjelpe deg med forespørselen din.',
    },
  },
};
