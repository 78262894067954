import { logApiError, logApiResponse } from '@utils';
import apiInstance from '../axios';
import { GET_COUNTRYCODE_LIST, GET_LANGUAGECODE_LIST, GET_MINICHAT_NEW_TOKEN, GET_MINICHAT_TOKEN, GET_NEW_TOKEN, GET_TOKEN } from '@constants';

export const getCountryCodeList = async () => {
  try {
    const response = await apiInstance.post(GET_COUNTRYCODE_LIST);
    logApiResponse('getCountryCodeList', response);
    return response;
  } catch (error) {
    logApiError('getCountryCodeList', error);
    throw error;
  }
};

export const getLanguageCodeList = async () => {
  try {
    const response = await apiInstance.post(GET_LANGUAGECODE_LIST);
    logApiResponse('getLanguageCodeList', response);
    return response;
  } catch (error) {
    logApiError('getLanguageCodeList', error);
    throw error;
  }
};

export const getToken = async (requestData) => {
  try {
    const response = await apiInstance.post(GET_TOKEN, requestData);
    logApiResponse('getToken', response);
    return response;
  } catch (error) {
    logApiError('getToken', error);
    throw error;
  }
};

export const getNewToken = async () => {
  try {
    const response = await apiInstance.post(GET_NEW_TOKEN, {});
    logApiResponse('getNewToken', response);
    return response;
  } catch (error) {
    logApiError('getNewToken', error);
    throw error;
  }
};

export const getMiniChatToken = async (requestData) => {
  try {
    const response = await apiInstance.post(GET_MINICHAT_TOKEN, requestData);
    if (response.message === 'no ip address') {
    }
    logApiResponse('getMiniChatToken', response);
    return response;
  } catch (error) {
    logApiError('getMiniChatToken', error);
    throw error;
  }
};

export const getNewMiniChatToken = async () => {
  try {
    const response = await apiInstance.post(GET_MINICHAT_NEW_TOKEN, {});
    logApiResponse('getNewMiniChatToken', response);
    return response;
  } catch (error) {
    logApiError('getNewMiniChatToken', error);
    throw error;
  }
};
