import { useEffect, useRef } from 'react';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { getDatabase, ref, onValue } from 'firebase/database';
import bookingStore from './booking.store';
import { FIREBASE_ENV } from '@constants';

interface miniChatType {
  newCallCenterChatCount: any;
  setNewCallCenterChatCount: (v: any) => void;
}

const useMiniChatStore = create(
  persist<miniChatType>(
    (set) => ({
      newCallCenterChatCount: {},
      setNewCallCenterChatCount: (v) => set(() => ({ newCallCenterChatCount: v })),
    }),
    {
      name: 'miniChat-store',
    },
  ),
);

export const useFireStore = () => {
  const { selectedCountry, requestId } = bookingStore();
  const { countryCode } = selectedCountry;

  const prevNewCallCenterChatRequestCount = useRef<number>();
  const db = getDatabase();

  const newCallCenterChatCount = useMiniChatStore((state) => state.newCallCenterChatCount);
  const setNewCallCenterChatCount = useMiniChatStore((state) => state.setNewCallCenterChatCount);

  useEffect(() => {
    prevNewCallCenterChatRequestCount.current = newCallCenterChatCount;
  }, [newCallCenterChatCount]);

  const countListenerByCountryCode = () => {
    const dbRef = ref(db, `${FIREBASE_ENV}/${countryCode}`);
    return onValue(dbRef, (snapshot) => {
      const data = snapshot.val();
      setNewCallCenterChatCount(data);
    });
  };

  const countListenerByRequestId = (_id: string) => {
    const dbRef = ref(db, `${FIREBASE_ENV}/${countryCode}/${_id}/dealer`);
    return onValue(dbRef, (snapshot) => {
      const data = snapshot.val();
      setNewCallCenterChatCount(data);
    });
  };

  useEffect(() => {
    const unsubscribehLiveCallCenter = countListenerByCountryCode();
    return () => {
      unsubscribehLiveCallCenter();
    };
  }, [countryCode]);

  useEffect(() => {
    const unsubscribehLiveCallCenter = countListenerByRequestId(requestId);
    return () => {
      unsubscribehLiveCallCenter();
    };
  }, [requestId]);

  return [
    newCallCenterChatCount,
    // prevNewCallCenterChatRequestCount.current,
  ];
};
