import { Box, IconButton, Link, Typography } from '@mui/material';
import { color, fonts } from '@theme';
import { commonDateFormat, commonTimeFormat } from '@utils';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { styles } from './styles';
import { ImageViewDialog } from '@components/ImageViewDialog';
import icon_file_attached from '@images/icon_file_attached.png';
import icon_file_download from '@images/icon_file_download.png';
import icon_image_download from '@images/icon_image_download.png';
import Vector from '@images/icon_vector.png';
import {
  LIVE_CALL_START,
  LIVE_CALL_END,
  H_LIVE_CHAT_END,
  WELCOME_H_LIVE_CHAT,
  DEALER_TRANSFER,
  ASSIGNED,
  SYSTEM,
  BLOCK_MESSAGE,
  CUSTOMER,
  H_LIVE_REQUESTED,
  CUSTOMER_JOIN,
  ACCEPTED,
  IMAGE,
  SOCKET_NAMESPACE,
  SOCKET_URI,
  HLIVE_SERVER_URI,
} from '@constants';
import { gtmDownloadFile } from '@gtm';
import { Manager } from 'socket.io-client';
import { updateIsDownloadChatCustomer } from '@apis';

import { hLiveEncryptData } from '@apis';

interface MessageTemplate {
  createDate?: string;
  customerRead: boolean;
  dealerRead: boolean;
  messageContents: string;
  messageType: string;
  requestId: string;
  systemMessageArgs?: any[];
  template: string;
  writer: string;
  _id?: string;
}

const ChatBubble = ({
  msg,
  dealerInfo,
  senderType = CUSTOMER,
  isFirstItem,
  onImageLoadEnd = (e) => {},
  imageDialogOpen = null,
  setImageDialogOpen = () => {},
}: {
  msg: MessageTemplate;
  dealerInfo: any;
  senderType?: string;
  isFirstItem?: boolean;
  isOffline?: boolean;
  onImageLoadEnd?: (e) => void;
  imageDialogOpen: number;
  setImageDialogOpen: (i) => void;
}) => {
  const { writer, template, createDate, messageType, messageContents, systemMessageArgs } = msg;
  const isSender = senderType.toUpperCase() === writer?.toUpperCase();
  const { t, i18n }: { t: (key: any, any?) => string; i18n } = useTranslation();

  //
  const [imageHeight, setImageHeight] = useState(100);
  const [imageSrc, setImageSrc] = useState('/thumbnail.png');
  const [imageBlob, setImageBlob] = useState<Blob | null>(null);
  const [fileName, setFileName] = useState('');
  const [isFetching, setIsFetching] = useState(false);

  // error 나와서
  // if (messageType === 'FILE' || messageType === 'PDF') {
  //   const fileUrl = messageContents.split('/');
  //   setFileName(fileUrl[fileUrl.length - 1]);
  // }

  //get token
  const getBearerToken = () => {
    const tokenData = sessionStorage.getItem('token-storage');
    if (tokenData) {
      try {
        const parsedData = JSON.parse(tokenData);
        return parsedData.token;
      } catch (error) {
        console.error('Failed to parse token-storage:', error);
      }
    }
    return null;
  };

  //fetch image
  const fetchImage = async (name: string): Promise<string | null> => {
    try {
      const fileName = name.split('/').pop() || '';
      setFileName(fileName);

      const encryptedData = await hLiveEncryptData(fileName);
      const token = getBearerToken();

      setIsFetching(true);
      const response = await fetch(`${HLIVE_SERVER_URI}/admin/callCenter/getFileByMiniChat?encryptedData=${encodeURIComponent(encryptedData)}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        const blob = await response.blob();
        setImageBlob(blob);
        return URL.createObjectURL(blob);
      } else {
        console.error('Failed to fetch image:', response.statusText);
      }
    } catch (error) {
      console.error('Error fetching image:', error);
    } finally {
      setIsFetching(false);
    }
  };

  useEffect(() => {
    const loadImage = async () => {
      try {
        if (msg.messageType === 'IMAGE' || msg.messageType === 'PDF') {
          const imageUrl = await fetchImage(msg.messageContents);
          setImageSrc(imageUrl);
        }
        return msg;
      } catch (error) {
        console.error('Error loading image:', error);
      }
    };

    loadImage();

    // Cleanup
    return () => {
      if (imageSrc && imageSrc !== '/thumbnail.png') {
        URL.revokeObjectURL(imageSrc);
      }
    };
  }, [messageContents]);

  const handleImageLoad = (e: React.SyntheticEvent<HTMLImageElement>) => {
    const img = e.currentTarget;
    setImageHeight((img.naturalHeight / img.naturalWidth) * 300);
  };

  const downloadImage = () => {
    if (!imageBlob || !(imageBlob instanceof Blob)) {
      console.error('Invalid or missing imageBlob:', imageBlob);
      return;
    }

    if (!fileName) {
      console.error('Missing fileName:', fileName);
      return;
    }

    gtmDownloadFile(msg.messageContents);
    const link = document.createElement('a');
    link.href = URL.createObjectURL(imageBlob);
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    URL.revokeObjectURL(link.href);
  };

  const imageBubbleStyle =
    messageType === IMAGE
      ? {
          p: 0,
          overflow: 'hidden',
          lineHeight: 0,
        }
      : {};

  const isURL = (str) => {
    try {
      new URL(str);
      return true;
    } catch (error) {
      return false;
    }
  };
  const handleDownload = async (msg) => {
    const manager = new Manager(SOCKET_URI, { transports: ['websocket'] });
    const _socket = manager.socket(SOCKET_NAMESPACE);
    _socket.emit('checkChat', { roomId: msg.requestId, sender: 'CUSTOMER', isDownload: true });

    try {
      await updateIsDownloadChatCustomer(msg._id);
    } catch (error) {
      console.error(error);
    }
    downloadImage();
    // if (message !== '') {
    // } else {
    //   _socket.emit('checkChat', { roomId: requestId, sender: 'CUSTOMER', isTyping: false });
    // }
    // gtmDownloadFile(msg?.messageContents, fileName);
  };

  return (
    <Box sx={{ marginBottom: '10px' }}>
      {isFirstItem && (
        <Box sx={{ padding: '12px 0', textAlign: 'center' }}>
          <Typography sx={styles.chatBubbleDate}>{commonDateFormat(createDate, i18n.language)}</Typography>
        </Box>
      )}

      {writer === SYSTEM && template === WELCOME_H_LIVE_CHAT ? (
        <Box sx={styles.systemMessage}>
          <Typography sx={styles.systemText}>{t('4510_chat_in_progress.welcome_to_hyundai_assistant')}</Typography>
          <Typography sx={styles.systemText}>{t('4510_chat_in_progress.feel_free_to_leave_us_a_message')}</Typography>
          <Typography sx={styles.systemText}>{t('4510_chat_in_progress.we_will_get_back_to_you_shortly')}</Typography>
        </Box>
      ) : writer === SYSTEM && template === ASSIGNED ? (
        <Box sx={styles.notificationText}>
          <Typography sx={styles.chatBubbleDate}>
            {systemMessageArgs[0].dealerFirstName
              ? `${systemMessageArgs[0].dealerFirstName}` + t('0500_live_consult.dealer_has_joined')
              : systemMessageArgs[0].dealertName
              ? `${systemMessageArgs[0].dealerName}` + t('0500_live_consult.dealer_has_joined')
              : t('4530_chat_common.consultant_has_joined_the_chat')}
          </Typography>
        </Box>
      ) : writer === SYSTEM && template === DEALER_TRANSFER ? (
        <Box sx={styles.notificationText}>
          <Typography sx={styles.chatBubbleDate}>{t('4530_chat_common.consultant_has_changed')}</Typography>
          <Typography sx={styles.chatBubbleDate}>
            {systemMessageArgs[1]} {t('0500_live_consult.dealer_has_joined')}
          </Typography>
        </Box>
      ) : writer === SYSTEM && template === BLOCK_MESSAGE ? (
        <Box sx={styles.systemMessage}>
          <Typography sx={styles.systemText}>{t('4530_chat_common.the_chat_has_been_ended_by_the_administrator')}</Typography>
        </Box>
      ) : writer === CUSTOMER && messageType === LIVE_CALL_START ? (
        <Box sx={styles.systemMessage}>
          <Typography sx={styles.systemText}>
            {dealerInfo?.dealerFirstName
              ? `${dealerInfo?.dealerFirstName}` + t('0500_live_consult.dealer_has_joined')
              : `${dealerInfo?.dealerName}` + t('0500_live_consult.dealer_has_joined')}
          </Typography>
          <Typography sx={styles.systemText}>{t('0500_live_consult.now_you_can_start_the_conversation')}</Typography>
        </Box>
      ) : messageType === LIVE_CALL_END || messageType === H_LIVE_CHAT_END ? (
        <Box sx={styles.notificationText}>
          <Typography sx={styles.chatBubbleDate}>{t('4530_chat_common.chat_ended')}</Typography>
        </Box>
      ) : writer === SYSTEM && (template === H_LIVE_REQUESTED || ACCEPTED || CUSTOMER_JOIN) ? (
        <Typography></Typography>
      ) : msg?.messageType === 'FILE' || msg?.messageType === 'PDF' ? (
        <>
          <Box sx={styles.pdfBubble}>
            <img style={styles.pdfIcon} src={icon_file_attached} alt="file_logo" />
            <Typography sx={styles.pdfAddress}>{fileName ? fileName : msg?.messageContents}</Typography>
            {!isFetching && <img style={styles.pdfIcon} src={icon_file_download} onClick={() => downloadImage()} alt="file_download" />}
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '4px' }}>
            <Typography sx={styles.chatBubbleDate}>{commonTimeFormat(createDate, i18n.language)}</Typography>
            {msg.dealerRead ? (
              <Box sx={{ width: '14px', height: '14px', display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '4px 0 0' }}>
                <img src={Vector} alt={Vector}></img>
              </Box>
            ) : (
              ''
            )}
          </Box>
        </>
      ) : (
        <Box display="flex" justifyContent={isSender ? 'flex-end' : 'flex-start'}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: isSender ? 'flex-end' : 'flex-start',
              flexWrap: 'wrap',
              maxWidth: '100%',
              height: '100%',
              overflowWrap: 'break-word',
            }}
          >
            <Typography
              sx={{
                // borderRadius: '10px',
                width: 'fit-content',
                fontSize: '0.875rem',
                padding: '8px 12px',
                ...fonts.b2_head_r,
                color: isSender ? color.grey_black : color.grey_black,
                backgroundColor: isSender ? color.secondary_sand : color.secondary_light_sand,
                display: 'block',
                wordBreak: 'break-word',
                maxWidth: '250px',
                minWidth: msg?.messageType === IMAGE ? 300 : 'fit-content',

                ...imageBubbleStyle,
              }}
            >
              {msg?.messageType === 'IMAGE' ? (
                <>
                  <img
                    src={imageSrc}
                    alt="msg_alt"
                    width="300"
                    height={imageHeight || 'auto'}
                    // loader={() => '/thumbnail.png'}
                    onLoad={handleImageLoad}
                    loading="lazy"
                    style={{ cursor: 'pointer' }}
                    onClick={() => setImageDialogOpen(0)}
                  />
                  <IconButton>
                    <img
                      onClick={() => downloadImage()}
                      style={{ width: '25px', height: '25px', position: 'absolute', left: '263px', bottom: '9px' }}
                      src={icon_image_download}
                      alt="icon_image_download"
                    />
                  </IconButton>
                  {imageDialogOpen !== null && (
                    <ImageViewDialog dataList={[{ src: imageSrc }]} itemIndex={imageDialogOpen} setItemIndex={setImageDialogOpen} />
                  )}
                </>
              ) : isURL(msg.messageContents) ? (
                <Link sx={styles.linkText} href={msg.messageContents} target="_blank" rel="noopener">
                  {msg.messageContents}
                </Link>
              ) : (
                msg.messageContents
              )}
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '4px' }}>
              <Typography sx={styles.chatBubbleDate}>{commonTimeFormat(createDate, i18n.language)}</Typography>
              {/* 확인여부 체크 */}
              {msg.dealerRead ? (
                <Box sx={{ width: '14px', height: '14px', display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '4px 0 0' }}>
                  <img src={Vector} alt={Vector}></img>
                </Box>
              ) : (
                ''
              )}
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default ChatBubble;
