import axios from 'axios';
import { GET_MINICHAT_NEW_TOKEN, GET_NEW_TOKEN, HLIVE_CUSTOMER_WEB, HLIVE_SERVER_URI, PUBLIC_URL, TOKEN_STORAGE } from '@constants';
import { getNewToken, getNewMiniChatToken } from '@apis';
import { consoleLogDev } from '@utils';

const apiInstance = axios.create({
  baseURL: HLIVE_SERVER_URI,
});

const handleError = (status) => {
  const urlPrefix = PUBLIC_URL;
  switch (status) {
    case 404:
      consoleLogDev('axios interceptor: 404 error');
      window.location.href = `${urlPrefix}/404`;
      break;
    case 500:
      consoleLogDev('axios interceptor: 500 error');
      // window.location.href = `${urlPrefix}/500`;
      break;
    case 502:
      consoleLogDev('axios interceptor: 502 error');
      window.location.href = `${urlPrefix}/502`;
      break;
    default:
      break;
  }
};

let isRefreshing = false;

const getTokenFromStorage = () => {
  const tokenStorage = JSON.parse(sessionStorage.getItem(TOKEN_STORAGE));
  return tokenStorage?.token;
};

const getRefreshTokenFromStorage = () => {
  const tokenStorage = JSON.parse(sessionStorage.getItem(TOKEN_STORAGE));
  return tokenStorage?.refreshToken;
};

const setTokenInStorage = (token) => {
  const tokenStorage = {
    token,
    refreshToken: getRefreshTokenFromStorage(),
  };
  sessionStorage.setItem(TOKEN_STORAGE, JSON.stringify(tokenStorage));
};

const startTokenRefresh = async (refreshToken, isCustomerWebRequest) => {
  try {
    const response = isCustomerWebRequest ? await getNewToken(refreshToken) : await getNewMiniChatToken(refreshToken);
    return response?.data?.token;
  } catch (error) {
    console.error(error);
  }
};

apiInstance.interceptors.request.use(
  async (config) => {
    const accessToken = getTokenFromStorage();
    const refreshToken = getRefreshTokenFromStorage();

    const isTokenRefresh = config.url === GET_NEW_TOKEN || config.url === GET_MINICHAT_NEW_TOKEN;
    config.headers.Authorization = isTokenRefresh ? `Bearer ${refreshToken}` : `Bearer ${accessToken}`;

    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

apiInstance.interceptors.response.use(
  async (response) => {
    if (response.status === 200 || response.status === 201) {
      if ('data' in response) {
      } else {
        consoleLogDev('API response success with empty data');
      }
    }
    return response.data;
  },
  async (error) => {
    if (error.response.status) {
      handleError(error.response.status);
    }

    if (error.response.status === 401) {
      const isCustomerWebRequest = error.config.url.startsWith(HLIVE_CUSTOMER_WEB);

      if (!isRefreshing) {
        isRefreshing = true;

        const refreshToken = getRefreshTokenFromStorage();
        if (refreshToken) {
          try {
            const newToken = await startTokenRefresh(refreshToken, isCustomerWebRequest);
            setTokenInStorage(newToken);
            error.config.headers.Authorization = `Bearer ${newToken}`;
            isRefreshing = false;
            return apiInstance(error.config);
          } catch (error) {
            console.error(error);
          }
        }
      }
    }

    return Promise.reject(error);
  },
);

export default apiInstance;
