import { useState, useEffect } from 'react';
import { Box, Fab } from '@mui/material';
import { styles } from './styles';
import icon_mini_chat_active from '@images/icon_mini_chat_active.png';
import icon_mini_chat_inactive from '@images/icon_mini_chat_inactive.png';
import MiniChat from '@components/MiniChat';
import i18n from 'i18next';
import bookingStore from '@store/booking.store';
import { getCountryCodeList, getLanguageCodeList } from '@apis';
import { gtmCloseAssistant, gtmOpenAssistant } from '@gtm';

export default function MiniChatRoot(props) {
  const { style, language, country, channelType, channelLink } = props;
  //dev
  // const [miniChatOpened, setMiniChatOpened] = useState(true);

  //staging
  const [miniChatOpened, setMiniChatOpened] = useState(false);

  //prod
  // const [miniChatOpened, setMiniChatOpened] = useState(false);

  useEffect(() => {
    if (language) {
      const newLanguage = `${language}`;
      const supportedLanguages = ['en', 'it', 'no', 'sk', 'nl', 'cs', 'pl', 'fr', 'es'];
      if (i18n && Array.isArray(supportedLanguages) && supportedLanguages.includes(newLanguage)) {
        i18n.changeLanguage(newLanguage);
        // console.log('🚀 ~ file: index.js:21 ~ useEffect ~ newLanguage:', newLanguage);
      } else {
        i18n.changeLanguage('en');
      }
    } else {
      const fetchData = async () => {
        const url = window.location.pathname;
        const [pre1, countryPrefix, languagePrefix, pre2, pre4] = url.split('/');

        const languageResponse = await getLanguageCodeList();
        if (languageResponse) {
          const { data: languageList } = languageResponse;
          // console.log('languageResponse', languageResponse);
          const selectedLanguage = languageList.find((language) => language.languageCode.toLowerCase() === languagePrefix) || 'en';
          // console.log('selectedLanguage', selectedLanguage);
          if (selectedLanguage) {
            setSelectedLanguage(selectedLanguage);
            i18n.changeLanguage(selectedLanguage.languageCode);
          }
        }
      };
      fetchData();
    }
  }, [language]);

  //--------------------------------------------------------------------------------------------------------------------------
  const { setSelectedCountry, setSelectedLanguage } = bookingStore();

  useEffect(() => {
    if (country) {
      setSelectedCountry({ country: country.toUpperCase(), countryCode: country });
      setSelectedLanguage({ languageCode: language });
    } else {
      const fetchData = async () => {
        const url = window.location.pathname;
        const [pre1, countryPrefix, languagePrefix, pre2, pre4] = url.split('/');

        const countryResponse = await getCountryCodeList();
        if (countryResponse) {
          const { data: countryList } = countryResponse;
          let selectedCountry =
            countryList.find((country) => country.countryCode.toLowerCase() === countryPrefix) ||
            countryList.find((country) => country.countryCode.toLowerCase() === languagePrefix) ||
            countryList.find((country) => country.countryCode.toLowerCase());
          if (selectedCountry) {
            setSelectedCountry(selectedCountry);
          }
        }
      };
      fetchData();
    }
  }, [country]);

  //
  const handleClick = () => {
    if (miniChatOpened) {
      gtmCloseAssistant();
      setMiniChatOpened(false);
    } else {
      gtmOpenAssistant();
      setMiniChatOpened(true);
    }
  };

  return (
    <Box
      sx={[
        styles.miniChatContainer,
        style,
        {
          height: miniChatOpened ? `calc(100vh - ${style.bottom} - 40px)` : 'auto',
        },
      ]}
    >
      {/* <Fab disableRipple onClick={() => setMiniChatOpened(!miniChatOpened)} sx={styles.miniChatButton}> */}
      <Fab disableRipple onClick={handleClick} sx={styles.miniChatButton}>
        {miniChatOpened ? (
          <img style={styles.miniChatIcon} src={icon_mini_chat_active} alt="icon_mini_chat_active" />
        ) : (
          <img style={styles.miniChatIcon} src={icon_mini_chat_inactive} alt="icon_mini_chat_inactive" />
        )}
      </Fab>

      {miniChatOpened && <MiniChat setMiniChatOpened={setMiniChatOpened} channelType={channelType} channelLink={channelLink} />}
    </Box>
  );
}
