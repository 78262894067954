import { color, fonts, fonts_mobile } from '@theme';

export const styles = {
  container: (theme) => ({
    position: 'relative',
    bottom: '20px',
    width: '372px',
    background: color.primary_blue,
    boxShadow: ' 0px 4px 10px rgba(0, 0, 0, 0.25)',
    zIndex: 5,
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    height: 'calc(100vh - 70px - 40px)',
    maxHeight: '616px',

    [theme.breakpoints.down('tablet')]: {
      position: 'absolute',
      width: '100vw',
      height: '100vh',
      right: '-30px',
      // bottom: '-30px',
      // bottom: '-70px',
      maxHeight: 'unset',
      zIndex: 5,
      top: '-40px',
    },
  }),

  spinnerBox: {
    zIndex: 10,
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },

  disclaimerBox: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: 7,
    background: color.grey_white,
  },

  disclaimerHeader: {
    // height: '60px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    background: color.primary_blue,
    padding: '16px 20px',
  },

  disclaimerBody: {
    padding: '40px 20px',
    height: `calc(100% - 170px)`,
    overflowY: 'scroll',
  },

  disclamierTitle: {
    ...fonts_mobile.b1_head_m,
    color: color.grey_700,
  },

  disclamierTextBox: {
    marginTop: '30px',
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
  },

  disclamierText: {
    ...fonts_mobile.b2_head_r,
    color: color.grey_600,
  },

  overlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: color.grey_400,
    opacity: 0.5,
    zIndex: 7,
  },

  wrapper: {
    background: color.primary_blue,
    padding: '0 20px',
  },

  header: {
    height: '60px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  headerTitle: {
    ...fonts_mobile.h3_head_m,
    color: color.grey_white,
  },

  button: {
    background: 'none',
    border: 'none',
    padding: 0,
  },

  banner: {
    display: 'flex',
    flexDirection: 'column',
    padding: '32px 0',
  },

  bannerTitle: {
    ...fonts_mobile.h1,
    color: color.grey_white,
    fontSize: '26px',
    lineHeight: '36px',
  },

  mainButton: {
    height: '60px',
    width: '100%',
    borderRadius: 0,
    background: color.grey_white,
    border: 'none',
    marginTop: '26px',
    textTransform: 'none',
    '&:hover': {
      background: color.grey_white,
    },
  },

  buttonText: {
    ...fonts_mobile.cta1,
    color: color.primary_blue,
  },

  dialogButtonText: {
    ...fonts_mobile.cta1,
  },

  footer: {
    height: '40px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 20px',
    background: color.grey_white,
    borderTop: `1px solid ${color.grey_300}`,
  },

  disclaimer: {
    ...fonts_mobile.label,
    color: color.primary_blue,
    textDecorationColor: color.primary_blue,
    textTransform: 'none',
    textAlign: 'left',
    textDecorationLine: 'underline',
    padding: 0,
  },

  footerText: {
    ...fonts_mobile.label,
    color: color.grey_600,
    textDecorationColor: color.grey_600,
    // textAlign: 'right',
  },

  dialogBox: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    background: color.grey_white,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '230px',
    zIndex: 15,
    padding: '50px 30px',
  },

  dialogTitle: {
    ...fonts.s1,
    marginBottom: '8px',
    fontSize: '17px',
    lineHeight: '22px',
  },

  dialogDescription: {
    ...fonts_mobile.b2_head_r,
    lineHeight: '18px',
    marginBottom: '40px',
  },

  buttonBox: {
    width: '140px',
  },

  chatListContainer: {
    flex: 1,
    background: color.secondary_light_sand,
    overflowX: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },

  chatListHeader: {
    background: color.grey_white,
    height: '50px',
    // position: 'relative',
    top: 0,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 20px',
    borderBottom: `1px solid ${color.grey_300}`,
  },

  chatListTitle: {
    ...fonts_mobile.h3_head_m,
    // color: color.primary_blue,
    color: color.grey_black,
  },

  chatListBox: {
    height: '242px',
  },

  clearButton: {
    ...fonts_mobile.b2_head_r,
    color: color.secondary_active_blue,
    textTransform: 'none',
    '&.no-chat-history': {
      color: color.grey_400,
    },
  },

  chatHistoryList: {
    background: color.grey_white,
    padding: '16px 20px',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContents: 'space-between',
    textTransform: 'none',
    gap: '8px',
    borderBottom: `1px solid ${color.grey_300}`,
  },

  chatHistoryListHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    // gap: '8px',
  },

  chatHistoryListContents: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    width: '100%',
    gap: '8px',
  },

  chatBox: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  chatTitle: {
    ...fonts_mobile.b1_head_m,
  },

  chatTime: {
    ...fonts_mobile.label,
    color: color.grey_400,
  },

  chatDate: {
    ...fonts_mobile.label,
    color: color.grey_400,
  },

  chatContent: {
    ...fonts_mobile.b2_head_r,
    color: color.grey_600,
    textAlign: 'left',
    overflowX: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },

  unreadIcon: {
    width: '20px',
    height: '20px',
    background: color.secondary_active_red,
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  unreadText: {
    ...fonts_mobile.b3_text_m,
    color: color.grey_white,
  },

  consentLink: {
    color: color.primary_blue,
    textDecorationColor: color.primary_blue,
  },
};
