import { color, fonts, fonts_mobile } from '@theme';

export const styles = {
  chatBubbleDate: {
    marginTop: '4px',
    ...fonts.b3_text_r,
    color: color.grey_600,
  },

  notificationText: {
    ...fonts_mobile.label,
    color: color.grey_600,
    padding: '12px 0',
    textAlign: 'center',
    flexDirection: 'column',
  },

  systemMessage: {
    background: color.grey_100,
    // borderRadius: '10px',
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    marginTop: '8px',
    padding: '8px 12px',
  },
  systemText: {
    ...fonts_mobile.b2_head_r,
    // color: color.secondary_gold,
    color: color.grey_600,
  },

  linkText: {
    ...fonts.b1_text_r,
    color: color.primary_blue,
    // textDecorationLine: 'none',
    textDecorationColor: color.primary_blue,
    wordBreak: 'break-all',
  },

  pdfChatBubble: {
    width: '261px',
    height: '38px',
    display: 'flex',
    alignItems: 'center',
    // borderRadius: '10px',
    padding: '10px 15px',
    background: color.grey_white,
  },

  pdfBubble: {
    width: '261px',
    height: '38px',
    // borderRadius: '10px',
    padding: '10px 15px',
    // background: 'white',
    background: color.secondary_light_sand,
    gap: '8px',
    display: 'flex',
    alignItems: 'center',
  },

  pdfAddress: {
    ...fonts_mobile.b2_head_m,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },

  pdfIcon: {
    width: '32px',
    height: '32px',
  },
};
