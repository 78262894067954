export const styles = {
  spinnerBox: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  spinnerLottie: {
    width: '50px',
    height: '50px',
  },
};
