export const SERVER_URI = process.env.REACT_APP_ENV_SERVER_URI;
export const HLIVE_SERVER_URI = process.env.REACT_APP_ENV_HLIVE_SERVER_URI;
export const SOCKET_URI = process.env.REACT_APP_ENV_SOCKET_URI;
export const SOCKET_NAMESPACE = process.env.REACT_APP_ENV_SOCKET_NAMESPACE;
export const SOCKET_NAMESPACE_NOTIFY = process.env.REACT_APP_ENV_SOCKET_NAMESPACE_NOTIFY;

export const SIGNAL_SOCKET_URI = process.env.REACT_APP_ENV_SIGNAL_SOCKET_URI;
export const SIGNAL_SOCKET_NAMESPACE = process.env.REACT_APP_ENV_SIGNAL_SOCKET_NAMESPACE;
export const UPLOAD_SERVER_URI = process.env.REACT_APP_ENV_UPLOAD_SERVER_URI;
export const WEBRTC_URI = process.env.REACT_APP_ENV_WEBRTC_URI;
export const WEBRTC_SLAVE_URI = process.env.REACT_APP_ENV_WEBRTC_SLAVE_URI;
export const WEBRTC_ROOM_URI = process.env.REACT_APP_ENV_WEBRTC_ROOM_URI;
export const WEBRTC_PORT = process.env.REACT_APP_ENV_WEBRTC_PORT;
export const WEBRTC_PATH = process.env.REACT_APP_ENV_WEBRTC_PATH;
export const EXTERNAL_URI = process.env.REACT_APP_ENV_EXTERNAL_URI;

export const DECRYPT_FIRST_KEY = process.env.REACT_APP_ENV_DECRYPT_FIRST_KEY;
export const DECRYPT_SECOND_KEY = process.env.REACT_APP_ENV_DECRYPT_SECOND_KEY;

export const EN = 'en';
export const IT = 'it';
export const ENGLISH = 'English';
export const ITALY = 'Italy';
export const ITALIAN = 'Italian';
export const ITALIANO = 'Italiano';

export const MODEL = 'model';
export const DEALERSHIP = 'dealership';
export const SCHEDULE = 'schedule';
export const CONTACT = 'contact';

export const H_LIVE = 'H_LIVE';
export const H_LIVE_REQUEST = 'H_LIVE_REQUEST';
export const H_LIVE_CHAT = 'H_LIVE_CHAT';
export const HYUNDAI_ASSISTANT = 'Hyundai Assistant';
export const CUSTOMER = 'CUSTOMER';
export const DEALER = 'DEALER';
export const SYSTEM = 'SYSTEM';
export const REQUESTED = 'REQUESTED';
export const CANCELLED = 'CANCELLED';
export const COMPLETED = 'COMPLETED';
export const FINISHED = 'FINISHED';
export const GRANTED = 'granted';
export const DENIED = 'denied';
export const HOLIDAY = 'holiday';
export const PROMPT = 'prompt';
export const CHANGE = 'change';
export const UNAUTHORIZED = 'Unauthorized';
export const UNAVAILABLE = 'unavailable';
export const GUEST = 'GUEST';
export const WAITING = 'WAITING';
export const REVERSED = 'reversed';
export const TOKEN_STORAGE = 'token-storage';

export const IMAGE = 'IMAGE';
export const TEXT = 'TEXT';
export const LIVE_CALL_START = 'LIVE_CALL_START';
export const LIVE_CALL_END = 'LIVE_CALL_END';
export const LIVE_CALL_CANCELLED = 'LIVE_CALL_CANCELLED';
export const H_LIVE_CHAT_END = 'H_LIVE_CHAT_END';
export const VOICE_CALL_START = 'VOICE_CALL_START';
export const VOICE_CALL_END = 'VOICE_CALL_END';
export const VOICE_CALL_CANCELLED = 'VOICE_CALL_CANCELLED';
export const H_LIVE_REQUESTED = 'H_LIVE_REQUESTED';
export const ACCEPTED = 'ACCEPTED';
export const CUSTOMER_JOIN = 'CUSTOMER_JOIN';
export const WELCOME_H_LIVE_CHAT = 'WELCOME_H_LIVE_CHAT';
export const ASSIGNED = 'ASSIGNED';
export const BLOCKED = 'BLOCKED';
export const DEALER_TRANSFER = 'DEALER_TRANSFER';
export const SERVICE_COMPLETED = 'SERVICE_COMPLETED';
export const BLOCK_MESSAGE = 'BLOCK_MESSAGE';
export const ERR_NETWORK = 'ERR_NETWORK';
export const ERR_BAD_REQUEST = 'ERR_BAD_REQUEST';
export const POINT = 'Point';
export const DUPLICATE_RESERVATION = 'duplicate reservation';
export const YOUR_IP_ADDRESS_IS_BLOCKED = 'your Ip address is blocked';

export const HEREMAPS_API_KEY = process.env.REACT_APP_ENV_HEREMAPS_API_KEY;
export const HEREMAPS_SECOND_API_KEY = process.env.REACT_APP_ENV_HEREMAPS_SECOND_API_KEY;
export const GOOGLEMAPS_API_KEY = process.env.REACT_APP_ENV_GOOGLE_MAPS_API_KEY;
export const GTM_KEY = process.env.REACT_APP_ENV_GOOGLE_TAG_MANAGER_KEY;

export const WEEKDAYS = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
export const KILOMETER = 'km';
export const MODELID = {
  modelCode: 'modelCode',
  bodyType: 'bodyType',
  specialFeature: 'specialFeature',
  indicator: 'indicator',
};

export const PUBLIC_URL = process.env.PUBLIC_URL;

export const IMPRINT = 'https://www.hyundai.com/it/imprint.html';
export const PRIVACY_POLICY = 'https://www.hyundai.com/it/informativa-e-consenso.html';
export const DISCLAIMER = ' https://www.hyundai.com/it/legal-disclaimer.html';
export const COOKIE = 'https://www.hyundai.com/it/cookie-policy.html';
export const COOKIE_POLICY = 'https://www.hyundai.com/it/configurazione-cookie.html';

export const FIREBASE_ENV = process.env.REACT_APP_ENV_FIREBASE;
export const FIREBASE_API_KEY = process.env.REACT_APP_ENV_FIREBASE_API_KEY;
export const FIREBASE_AUTH_DOMAIN = process.env.REACT_APP_ENV_FIREBASE_AUTH_DOMAIN;
export const FIREBASE_DATABASE_URL = process.env.REACT_APP_ENV_FIREBASE_DATABASE_URL;
export const FIREBASE_PROJECT_ID = process.env.REACT_APP_ENV_FIREBASE_PROJECT_ID;
export const FIREBASE_STORAGE_BUCKET = process.env.REACT_APP_ENV_FIREBASE_STORAGE_BUCKET;
export const FIREBASE_MESSAGING_SENDER_ID = process.env.REACT_APP_ENV_FIREBASE_MESSAGING_SENDER_ID;
export const FIREBASE_APP_ID = process.env.REACT_APP_ENV_FIREBASE_APP_ID;
export const FIREBASE_MEASUREMENT_ID = process.env.REACT_APP_ENV_FIREBASE_MEASUREMENT_ID;

export const NODE_ENV = process.env.REACT_APP_ENV_NODE_ENV;
export const DEV = 'development';
export const STG = 'staging';
export const PROD = 'production';

// Auth APIs
export const GET_TOKEN = '/auth/loginNoUser';
export const GET_NEW_TOKEN = '/auth/generateNewToken';
export const GET_MINICHAT_TOKEN = '/auth/miniChatloginNoUser';
export const GET_MINICHAT_NEW_TOKEN = '/auth/miniChatGenerateNewToken';

// Online booking
export const GET_COUNTRYCODE_LIST = '/common/settings/getCountryCodeList';
export const GET_LANGUAGECODE_LIST = '/common/settings/getLanguageCodeList';
export const GET_VEHICLE_INFO = '/hLiveCustomerWeb/getModelByModelId';
export const GET_DEALERSHIP_INFO = '/hLiveCustomerWeb/getDealershipByDealershipCode';
export const GET_CONSULT_INFO = '/hLiveCustomerWeb/getConsultInfoByCountryCode';

// Booking steps
export const HLIVE_CUSTOMER_WEB = '/hLiveCustomerWeb';
export const CREATE_REQUEST = '/hLiveCustomerWeb/createHLiveRequest';
export const UPDATE_REQUEST = '/hLiveCustomerWeb/updateServiceRequest';
export const UPDATE_LEAD = '/hLiveCustomerWeb/updateLeadData';

export const GET_HPP_MODELS = '/hLiveCustomerWeb/getVehicleModels';
export const GET_DEALERSHIP_COUNTRYCODE = '/hLiveCustomerWeb/getDealershipByCountry';
export const GET_RESERVATION_INFO = '/hLiveCustomerWeb/getReservationInfoByDealer';
export const CHECK_DUPLICATION = '/hLiveCustomerWeb/checkDuplicationReservation';
export const SEARCH_AUTOCOMPLETE = '/hLiveCustomerWeb/searchAutoComplete';
export const GET_PRIVACY_POLICY = '/hLiveCustomerWeb/getPrivacyPolicyByCountryCode';

// Live consultation
export const GET_TOKEN_REQUESTID = '/hLiveCustomerWeb/getTokenByRequestId';
export const ADD_CHAT = '/hLiveCustomerWeb/addChat';
export const GET_MESSAGE_LIST = '/hLiveCustomerWeb/getMessageList';
export const GET_BROCHURE = '/hLiveCustomerWeb/getBrochure';

// Call center mini chat
export const CREATE_CALLCENTER_REQUEST = '/admin/callCenter/createHLiveChatRequest';
export const UPDATE_CALLCENTER_REQUEST = '/admin/callCenter/updateServiceRequestCustomer';

export const GET_CALLCENTER_HISTORY = '/admin/callCenter/getCallCenterCustomerHistory';
export const DELETE_CALLCENTER_HISTORY = '/admin/callCenter/clearHistory';
export const GET_CALLCENTER_MESSAGE_LIST = '/admin/callCenter/getMessageListCustomer';
export const ADD_CALLCENTER_CHAT = '/admin/callCenter/addChatCustomer';
export const UPDATE_IS_DOWNLOAD_CALLCENTER_CHAT = '/admin/callCenter/updateIsDownloadChatCustomer';
export const GET_CALLCENTER_BUSINESSHOUR = 'admin/callCenter/getBusinessHourByCountry';
