import { color, fonts, fonts_mobile } from '@theme';

export const styles = {
  container: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
  },

  overlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: color.grey_400,
    opacity: 0.5,
    zIndex: 7,
  },

  buttonBox: {
    width: '140px',
  },

  dialogBox: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    background: color.grey_white,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '230px',
    zIndex: 15,
    padding: '50px 30px',
  },

  dialogTitle: {
    ...fonts.s1,
    marginBottom: '8px',
    fontSize: '17px',
    lineHeight: '22px',
  },

  dialogDescription: {
    ...fonts_mobile.b2_head_r,
    lineHeight: '18px',
    marginBottom: '40px',
  },

  chatWrapper: {
    flex: 1,
    display: 'block',
    // background: color.secondary_light_sand,
    background: color.grey_white,
    overflowY: 'scroll',
    // borderRadius: '10px 10px 0px 0px',
  },

  chatContainer: {
    // display: 'grid',
    padding: '0 20px',
    flex: 1,
    minHeight: '425px',
  },

  header: {
    background: color.primary_blue,
    height: '60px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 20px',
  },

  headerTitle: {
    ...fonts_mobile.h3_head_m,
    color: color.grey_white,
  },

  headerBackButton: {
    padding: 0,
    minWidth: 0,
    marginRight: '12px',
  },

  headerCloseButton: {
    padding: 0,
    minWidth: 0,
  },

  chatInput: {
    background: 'white',
    width: '100%',
    '&.chat-input-disabled': {
      display: 'none',
    },
  },

  chatToolBox: {
    display: 'flex',
    alignSelf: 'flex-end',
    padding: '0 20px 12px',
  },

  chatInputContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
    flexGrow: 1,
    borderTop: `1px solid ${color.grey_300}`,
  },

  chatFileIcon: {
    cursor: 'pointer',
    width: '32px',
    height: '32px',
  },

  chatSendIcon: {
    cursor: 'pointer',
    width: '30px',
    height: '30px',
  },

  chatInputfield: {
    width: '100%',
    border: 'none',
    ...fonts_mobile.b1_text_r,
    lineHeight: '25px',
    color: color.grey_900,
    outline: 'none',
    '& .MuiOutlinedInput-root': {
      ...fonts_mobile.b1_text_r,
      padding: 0,
    },
    '& fieldset': { border: '0 !important' },
  },

  divider: {
    borderTop: `1px solid ${color.grey_300}`,
  },

  notification: {
    background: color.grey_white,
    // borderRadius: '10px',
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    margin: '20px 20px 16px 20px',
    padding: '8px 12px',
    ...fonts_mobile.b2_head_r,
    color: color.secondary_gold,
  },

  button: {
    padding: 0,
    minWidth: 0,
  },

  date: {
    ...fonts_mobile.label,
    color: color.grey_600,
    marginTop: '12px',
    textAlign: 'center',
  },

  ratingContainer: {
    background: color.grey_100,
    padding: '24px 12px',
    margin: '0 0 20px 0',
    // borderRadius: '10px',
    ...fonts_mobile.b2_head_r,
    display: 'flex',
    flexDirection: 'column',
    // alignItems: 'center',
    justifyContent: 'center',
    gap: '16px',
  },

  submitted: {
    padding: '50px 18px',
    background: color.grey_100,
    margin: '0 0 20px 0',
    // borderRadius: '10px',
    ...fonts_mobile.b2_head_r,
    textAlign: 'center',
  },

  ratingBox: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    padding: '12px 40px',
    gap: '8px',
  },

  ratingTitle: {
    ...fonts_mobile.s1,
  },

  ratingSubtitle: {
    ...fonts_mobile.b2_head_r,
    color: color.grey_700,
  },

  submitButton: {
    ...fonts_mobile.cta1,
    height: '50px',
    width: '155px',
    color: color.grey_white,
    background: color.primary_blue,
    border: 'none',
    textTransform: 'none',

    '&.Mui-disabled': {
      ...fonts_mobile.cta1,
      background: color.grey_300,
      border: `1px solid ${color.grey_300}`,
      color: color.grey_600,
    },
  },

  submitWrapper: {
    display: 'flex',
    justifyContent: 'center',
  },

  buttonText: {
    ...fonts_mobile.cta1,
    color: color.primary_blue,
  },

  // submitText: {
  //   ...fonts_mobile.cta1,
  //   color: color.grey_white,
  // },

  systemMessage: {
    background: color.grey_100,
    // borderRadius: '10px',
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    marginTop: '8px',
    padding: '8px 10px',
  },
  systemText: {
    ...fonts_mobile.b2_head_r,
    color: color.grey_600,
  },

  chatDateBox: {
    padding: '12px 0',
    textAlign: 'center',
  },

  chatBubbleDate: {
    marginTop: '4px',
    ...fonts.b3_text_r,
    color: color.grey_600,
  },

  uploading: {
    ...fonts.b2_head_r,
    position: 'absolute',
    padding: '10px',
    width: '100%',
    height: '100%',
    display: 'flex',
    left: '30px',
  },
  fileRemove: {
    position: 'absolute',
    border: 'none',
    left: '130px',
    top: '5px',
    padding: 0,
    color: color.grey_black,
  },

  spinnerBox: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
};
