import {
  ADD_CALLCENTER_CHAT,
  GET_CALLCENTER_BUSINESSHOUR,
  GET_CALLCENTER_HISTORY,
  DELETE_CALLCENTER_HISTORY,
  GET_CALLCENTER_MESSAGE_LIST,
  CREATE_CALLCENTER_REQUEST,
  UPDATE_CALLCENTER_REQUEST,
  UPDATE_IS_DOWNLOAD_CALLCENTER_CHAT,
} from '@constants';
import apiInstance from '../axios';
import { encryptData, logApiError, logApiResponse } from '@utils';
import * as CryptoJS from 'crypto-js';

export const getCallCenterCustomerHistory = async () => {
  try {
    const response = await apiInstance.post(GET_CALLCENTER_HISTORY, {});
    logApiResponse('getCallCenterCustomerHistory', response);
    return response;
  } catch (error) {
    logApiError('getCallCenterCustomerHistory', error);
  }
};

export const getBusinessHourByCountry = async () => {
  try {
    const response = await apiInstance.post(GET_CALLCENTER_BUSINESSHOUR, {});
    logApiResponse('getBusinessHourByCountry', response);
    return response;
  } catch (error) {
    logApiError('getBusinessHourByCountry', error);
  }
};

export const deleteCallCenterHistory = async (ids) => {
  try {
    const response = await apiInstance.post(DELETE_CALLCENTER_HISTORY, { ids });
    logApiResponse('deleteCallCenterHistory', response);
    return response;
  } catch (error) {
    logApiError('deleteCallCenterHistory', error);
  }
};

export const getMessageListCustomer = async (requestId) => {
  try {
    const encryptedBody = await encryptData({ requestId });
    const response = await apiInstance.post(GET_CALLCENTER_MESSAGE_LIST, { encryptedBody });
    logApiResponse('getMessageListCustomer', response);
    return response;
  } catch (error) {
    logApiError('getMessageListCustomer', error);
  }
};

export const createHLiveChatRequest = async (requestData) => {
  try {
    const encryptedBody = await encryptData(requestData);
    const response = await apiInstance.post(CREATE_CALLCENTER_REQUEST, { encryptedBody });
    // console.log('🚀 ~ createHLiveChatRequest ~ response:', response);
    logApiResponse('createHLiveChatRequest', response);
    return response;
  } catch (error) {
    logApiError('createHLiveChatRequest', error);
  }
};

export const updateServiceRequestCustomer = async (requestData) => {
  try {
    const encryptedBody = await encryptData(requestData);
    const response = await apiInstance.post(UPDATE_CALLCENTER_REQUEST, { encryptedBody });
    logApiResponse('updateServiceRequestCustomer', response);
    return response;
  } catch (error) {
    logApiError('updateServiceRequestCustomer', error);
  }
};

export const addChatCustomer = async (dto) => {
  try {
    const response = await apiInstance.post(ADD_CALLCENTER_CHAT, { dto });
    logApiResponse('addChatCustomer', response);
    return response;
  } catch (error) {
    logApiError('addChatCustomer', error);
  }
};

export const updateIsDownloadChatCustomer = async (_id) => {
  try {
    const response = await apiInstance.post(UPDATE_IS_DOWNLOAD_CALLCENTER_CHAT, { _id });
    logApiResponse('updateIsDownloadChatCustomer', response);
    return response;
  } catch (error) {
    logApiError('updateIsDownloadChatCustomer', error);
  }
};

//11.14
export const hLiveEncryptData = async (data) => {
  const firstSaltKey = process.env.REACT_APP_ENV_DECRYPT_FIRST_KEY;
  const secondSaltKey = process.env.REACT_APP_ENV_DECRYPT_SECOND_KEY.substring(4, 17);

  // Description) 첫 번 째키를 이용하여 1차 암호화
  const cryptoTest = CryptoJS.AES.encrypt(JSON.stringify(data), firstSaltKey).toString();

  // Description) 두 번 째키를 이용하여 2차 암호화 (logical 암호화)
  const encryptAgain = CryptoJS.AES.encrypt(cryptoTest, secondSaltKey).toString();

  return encryptAgain;
};
