import React, { CSSProperties } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import MiniChatRoot from '@components/MiniChatRoot';
import './i18n';
import TagManager from 'react-gtm-module';

type customWindow = Window & { hliveButton? };

interface HliveChatConfig {
  style?: CSSProperties;
  language;
  country;
  channelType?: string;
  channelLink;
}

export default class HliveChat {
  htmlElementRoot: HTMLElement;
  config: HliveChatConfig;

  constructor(config) {
    this.htmlElementRoot = config?.htmlElementRoot || document.body;
    this.config = config;
    this.render();
  }

  render() {
    if ((window as any).hliveButton) {
      console.error('');
      return;
    } else {
      const div = document.createElement('div', {});
      div.id = 'hlive-minichat-root';

      this.htmlElementRoot.appendChild(div);
      const ref = React.createRef();
      const root = ReactDOM.createRoot(div);

      root.render(
        <Router>
          <MiniChatRoot
            ref={ref}
            style={this.config?.style}
            language={this.config?.language}
            country={this.config?.country}
            channelType={this.config?.channelType}
            channelLink={this.config?.channelLink}
          />
        </Router>,
      );
    }
  }
}
